import React from 'react';
import { RouteComponentProps, Link as RouterLink } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import ExpansionPanel from 'components/ExpansionPanel';
import ExpansionPanelDenseSummary from 'components/ExpansionPanelDenseSummary';
import ExpansionPanelDetails from 'components/ExpansionPanelDetails';

import noSleep from 'utils/noSleep';

import Page from 'components/Page';
import Content from 'components/Content';
import AppBarDefault from 'components/AppBarDefault';
import NavMenuDefault from 'components/NavMenuDefault';
import { RecipeType } from 'types/recipes';
import { GET_RECIPES } from 'queries';
import WarningNotificationTooltipButton from 'components/WarningNotificationTooltipButton';
import LoadingPlaceholder from 'components/LoadingPlaceholder';

type TParams = { id: string };

const useStyles = makeStyles(({ typography }) => ({
  panel: {
    boxShadow: 'none',
    marginTop: 0
  },
  header: {
    color: 'inherit',
    fontSize: typography.pxToRem(15),
    fontWeight: typography.fontWeightMedium,
    textTransform: 'uppercase'
  },
  list: {
    flexGrow: 1
  },
  listItem: {
    '&:nth-child(odd)': {
      backgroundColor: '#F4F5F5'
    }
  }
}));

type Response = {
  recipes: RecipeType[];
};

type Variables = {};

// Since this is the landing page, we need page title to be what the bookmark should be
const pageTitle = 'Honeybear Eats';
const title = 'All Recipes';
const TAG_UNCATEGORIZED = 'Uncategorized';

type RecipeListContentProps = {
  recipes: RecipeType[];
};

type TagToRecipeMap = {
  [key: string]: RecipeType[];
};

const getRecipesByTag = (recipes: RecipeType[]) => {
  const tagToRecipeMap: TagToRecipeMap = {};

  recipes.forEach(recipe => {
    if (recipe.tags && recipe.tags.length > 0) {
      recipe.tags.forEach(tag => {
        const tagName = tag.name;
        if (!tagToRecipeMap[tagName]) {
          tagToRecipeMap[tagName] = [];
        }
        tagToRecipeMap[tagName].push(recipe);
      });
    } else {
      // If no tags are defined, push it into a catch-all bucket
      if (!tagToRecipeMap[TAG_UNCATEGORIZED]) {
        tagToRecipeMap[TAG_UNCATEGORIZED] = [];
      }
      tagToRecipeMap[TAG_UNCATEGORIZED].push(recipe);
    }
  });

  return tagToRecipeMap;
};

function addOpenTag(tag: string) {
  const currentTags = getOpenTags();
  if (currentTags && currentTags.indexOf(tag) === -1) {
    currentTags.push(tag);
  }
  sessionStorage.setItem('tags', JSON.stringify(currentTags));
}

function removeOpenTag(tag: string) {
  const currentTags = getOpenTags();
  const targetTagIndex = currentTags.indexOf(tag);

  let newTags = currentTags;
  if (currentTags && targetTagIndex !== -1) {
    newTags = currentTags.slice(targetTagIndex, 1);
  }
  sessionStorage.setItem('tags', JSON.stringify(newTags));
}

function getOpenTags(): string[] {
  const rawTags = sessionStorage.getItem('tags');
  return rawTags ? JSON.parse(rawTags) : [];
}

function RecipeListContent({ recipes }: RecipeListContentProps) {
  const tagToRecipeMap = getRecipesByTag(recipes);
  const openTags = getOpenTags();
  const classes = useStyles();

  // Get tag names and sort alphabetically
  const tags = Object.keys(tagToRecipeMap);
  tags.sort(function(a, b) {
    return a.toLowerCase().localeCompare(b.toLowerCase());
  });

  return (
    <Content>
      <Typography variant="h1">{title}</Typography>
      {tags.map(tag => {
        const shouldExpand =
          tag === '*favorites' || openTags.indexOf(tag) !== -1; // This can be tweaked a little
        const recipes = tagToRecipeMap[tag];
        return (
          <ExpansionPanel
            TransitionProps={{ unmountOnExit: true }}
            className={classes.panel}
            key={tag}
            defaultExpanded={shouldExpand}
            onChange={(event, expanded) => {
              if (expanded) {
                addOpenTag(tag);
              } else {
                removeOpenTag(tag);
              }
            }}
          >
            <ExpansionPanelDenseSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.header}>
                {tag} ({recipes.length})
              </Typography>
            </ExpansionPanelDenseSummary>
            <ExpansionPanelDetails>
              <List className={classes.list} dense disablePadding>
                {recipes &&
                  recipes.length > 0 &&
                  recipes.map(recipe => (
                    <ListItem
                      classes={{
                        root: !recipe.warning ? classes.listItem : undefined, // Having a warning causes ListItem to create a container
                        container: classes.listItem
                      }}
                      button
                      component={RouterLink}
                      to={{
                        pathname: `/recipe/${recipe._id}`,
                        state: {
                          useHistoryBack: true
                        }
                      }}
                      onClick={() => {
                        noSleep.enable();
                      }}
                      key={recipe._id}
                    >
                      <ListItemText primary={recipe.name} />
                      {recipe.warning && (
                        <ListItemSecondaryAction>
                          <WarningNotificationTooltipButton
                            warning={recipe.warning}
                          />
                        </ListItemSecondaryAction>
                      )}
                    </ListItem>
                  ))}
              </List>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        );
      })}
    </Content>
  );
}

function RecipeListPage({ match }: RouteComponentProps<TParams>) {
  const { loading, error, data } = useQuery<Response, Variables>(GET_RECIPES, {
    fetchPolicy: 'cache-and-network'
  });

  return (
    <Page title={pageTitle}>
      <AppBarDefault />
      {error && <div>Error: ${error.message}</div>}
      {loading && (!data || !data.recipes) && <LoadingPlaceholder />}
      {data && data.recipes && <RecipeListContent recipes={data.recipes} />}
      <NavMenuDefault />
    </Page>
  );
}

export default RecipeListPage;
