import { RecipeType, RecipeInputType } from 'types/recipes';

const formatRecipeInput = ({
  directionLists,
  ingredientLists,
  images,
  servingSize,
  prepTime,
  cookTime,
  ...rest
}: RecipeType): RecipeInputType => {
  const filteredIngredientList = ingredientLists
    .map(({ title, ingredients }) => {
      return {
        title,
        ingredients: ingredients.filter(
          ingredient => ingredient.name.trim() !== ''
        )
      };
    })
    .filter(({ ingredients }) => ingredients.length > 0);

  const filteredDirectionsList = directionLists
    .map(({ title, directions }) => {
      return {
        title,
        directions: directions.filter(direction => direction.text.trim() !== '')
      };
    })
    .filter(({ directions }) => directions.length > 0);

  const formattedValues = {
    ...rest,
    ingredientLists: filteredIngredientList,
    directionLists: filteredDirectionsList,
    images: images
      ? images.map(image => ({
          uploadId: image.uploadId
        }))
      : [],
    servingSize: servingSize || null,
    prepTime: prepTime || null,
    cookTime: cookTime || null
  };

  return formattedValues;
};

export default formatRecipeInput;
