import React, { CSSProperties } from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { ValueType } from 'react-select/src/types';
import { TagType } from 'types/recipes';
import { FieldProps } from 'formik';
import { ApolloClient, useApolloClient } from '@apollo/client';
import { SEARCH_TAGS } from 'queries';

const styles = {
  menu: (base: CSSProperties): CSSProperties => ({
    ...base,
    zIndex: 2
  }),
  multiValue: (base: CSSProperties): CSSProperties => ({
    ...base,
    backgroundColor: '#2196f3',
    borderRadius: '20px'
  }),
  multiValueLabel: (base: CSSProperties): CSSProperties => ({
    ...base,
    color: '#ffffff',
    borderRadius: '20px',
    paddingLeft: '12px'
  }),
  multiValueRemove: (base: CSSProperties): CSSProperties => ({
    ...base,
    color: '#ffffff',
    // @ts-ignore
    ':hover': {
      color: '#ffffff',
      backgroundColor: '#40A0FC',
      borderRadius: '20px'
    }
  })
};

interface Option {
  label: string;
  value: string;
}

type FormValue = {
  tags: TagType[];
};

type Props = {
  isMulti?: boolean;
  className?: string;
  placeholder?: string;
  tags: TagType[];
};

// Convert TagType to options that react-select can use
const getOptionsFromTags = (tags: TagType[]) => {
  if (!tags) {
    return [];
  }
  return tags.map(tag => ({
    label: tag.name.toLowerCase(),
    value: tag.name.toLowerCase()
  }));
};

async function searchTags(client: ApolloClient<any>, input: string) {
  const { data } = await client.query({
    query: SEARCH_TAGS,
    variables: { query: input }
  });

  // @ts-ignore
  return data.searchTags.map(tag => ({
    label: tag.name.toLowerCase(),
    value: tag.name.toLowerCase()
  }));
}

function TagListEdit({
  className,
  field,
  form,
  tags
}: Props & FieldProps<FormValue>) {
  const client = useApolloClient();
  // onChange we convert the value to a TagType
  const handleChange = (value: ValueType<any>) => {
    const newValue = value
      ? (value as Option[]).map((option: Option) => ({
          name: option.value.toLowerCase()
        }))
      : [];

    form.setFieldValue(field.name, newValue);
  };

  return (
    <AsyncCreatableSelect
      className={className}
      isMulti
      styles={styles}
      onChange={handleChange}
      value={getOptionsFromTags(tags)}
      // options={[]}
      loadOptions={inputValue => {
        return searchTags(client, inputValue);
      }}
    />
  );
}

export default TagListEdit;
