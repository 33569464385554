import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { RecipeType } from 'types/recipes';
import Typography from '@material-ui/core/Typography';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import TimeCookIcon from 'icons/TimeCookIcon';
import TimePrepIcon from 'icons/TimePrepIcon';

import HourglassRoundIcon from 'icons/HourglassRoundIcon';

const useStyles = makeStyles(({ palette, typography }) => ({
  root: {
    marginLeft: 8
  },
  section: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 4
  },
  icon: {
    color: '#76888C',
    fontSize: '18px',
    marginRight: '3px'
  },
  text: {
    color: '#344146',
    fontWeight: 500
  },
  warningNotification: {
    fontSize: 12,
    display: 'flex',
    marginBottom: 8,
    marginTop: 16
  },
  warningNotificationIcon: {
    marginRight: 8
  }
}));

function convertTimeToShorthand(mins: number) {
  const hours = Math.floor(mins / 60);
  const minutes = mins % 60;

  let formattedString = '';
  if (hours) {
    formattedString = hours + ' hr' + (hours > 1 ? 's' : '');
  }
  if (minutes) {
    if (formattedString) {
      formattedString += ' ';
    }
    formattedString += minutes + ' min' + (minutes > 1 ? 's' : '');
  }

  return formattedString;
}

type ViewRecipeStats = {
  recipe: RecipeType;
};

const ViewRecipeStats = ({ recipe }: ViewRecipeStats) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {recipe.prepTime && (
        <div className={classes.section}>
          <TimePrepIcon width={18} height={18} className={classes.icon} />{' '}
          <Typography className={classes.text}>
            {convertTimeToShorthand(parseInt(recipe.prepTime, 10))}
          </Typography>
        </div>
      )}
      {recipe.cookTime && (
        <div className={classes.section}>
          <TimeCookIcon width={18} height={18} className={classes.icon} />{' '}
          <Typography className={classes.text}>
            {convertTimeToShorthand(parseInt(recipe.cookTime, 10))}
          </Typography>
        </div>
      )}
      {recipe.cookTime && recipe.prepTime && (
        <div className={classes.section}>
          <AccessTimeIcon
            width={18}
            height={18}
            color="inherit"
            className={classes.icon}
          />{' '}
          <Typography className={classes.text}>
            {convertTimeToShorthand(
              parseInt(recipe.prepTime, 10) + parseInt(recipe.cookTime, 10)
            )}
          </Typography>
        </div>
      )}
      {recipe.servingSize && (
        <div className={classes.section}>
          <PersonOutlineIcon
            width={18}
            height={18}
            color="inherit"
            className={classes.icon}
          />
          <Typography className={classes.text}>{recipe.servingSize}</Typography>
        </div>
      )}
      {recipe.warning && (
        <div className={classes.warningNotification} color="inherit">
          <HourglassRoundIcon className={classes.warningNotificationIcon} />
          {recipe.warning}
        </div>
      )}
    </div>
  );
};

export default ViewRecipeStats;
