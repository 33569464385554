import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ImageType } from 'types/recipes';

type Props = {
  image: ImageType;
};

const useStyles = makeStyles(() => {
  return {
    root: {
      width: 80,
      height: 80,
      borderRadius: '4px'
    },
    image: {
      maxWidth: 80,
      maxHeight: 80,
      borderRadius: '4px'
    }
  };
});

const ImageThumbnail = ({ image }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img
        className={classes.image}
        src={image.miniThumbUrl}
        alt={image.filename}
      />
    </div>
  );
};

export default ImageThumbnail;
