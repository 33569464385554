import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import { withStyles } from '@material-ui/core/styles';

const ExpansionPanelSummary = withStyles({
  root: {
    borderBottom: '1px solid rgba(0,0,0,.125)',
    marginBottom: -1,
    minHeight: 48,
    '&$expanded': {
      minHeight: 48
    },
    paddingLeft: 0,
    paddingRight: '12px'
  },
  content: {
    '&$expanded': {
      margin: '8px 0'
    }
  },
  expanded: {}
})(MuiExpansionPanelSummary);

export default ExpansionPanelSummary;
