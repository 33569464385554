import React from 'react';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';

export default createSvgIcon(
  <React.Fragment>
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g transform="translate(-294.000000, -368.000000)" stroke="#76888C">
        <g transform="translate(295.000000, 368.000000)">
          <g>
            <path d="M5.6355,0.375 C4.3365,1.5 6.9345,2.25 5.6355,3.375" />
            <path d="M9,0.375 C7.701,1.5 10.299,2.25 9,3.375" />
            <path d="M12.375,0.375 C11.076,1.5 13.674,2.25 12.375,3.375" />
            <path d="M15.375,10.875 L17.625,10.875" />
            <path d="M0.375,10.875 L2.625,10.875" />
            <path d="M7.875,5.625 L7.875,7.17225" />
            <path d="M10.125,7.17225 L10.125,5.625" />
            <path d="M7.125,5.625 L10.875,5.625" />
            <circle cx="9" cy="13.8795" r="1.1295" />
            <path d="M12,12.75 L12.75,12.75" />
            <path d="M12,15 L12.75,15" />
            <path d="M15.375,10.125 L15.375,16.125 C15.375,16.9534271 14.7034271,17.625 13.875,17.625 L4.125,17.625 C3.29657288,17.625 2.625,16.9534271 2.625,16.125 L2.625,10.125 C2.625,10.125 3.87525,7.1205 9,7.1205 C14.12475,7.1205 15.375,10.125 15.375,10.125 Z" />
            <path d="M2.625,10.125 L15.375,10.125" />
          </g>
        </g>
      </g>
    </g>
  </React.Fragment>,
  'TimeCookIcon'
);
