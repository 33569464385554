import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { MuiThemeProvider, makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  ApolloProvider,
  ApolloClient,
  ApolloLink,
  InMemoryCache
} from '@apollo/client';
import { onError } from '@apollo/link-error';
import { createUploadLink } from 'apollo-upload-client';
import { SnackbarProvider } from 'notistack';

import theme from 'styles/theme';
import LoginPage from './pages/login-page';
import RecipeListPage from './pages/recipe-list-page';
import ViewRecipePage from './pages/view-recipe-page';
import ViewRecipeHistoryPage from './pages/view-recipe-history-page';
import CreateRecipePage from './pages/create-recipe-page';
import EditRecipePage from './pages/edit-recipe-page';
import RecentsPage from './pages/recents-page';
import MeatTemperaturePage from './pages/meat-temperature-page';
import SearchPage from './pages/search-page';
import TestPage from './pages/test-page';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);

  // @ts-ignore
  if (networkError && networkError.statusCode === 401) {
    window.location.href = '/login';
  }
});

const uploadLink = createUploadLink({
  uri: 'https://recipes.honeybeareats.com/graphql',
  credentials: 'include'
});

// @ts-ignore
const links = ApolloLink.from([errorLink, uploadLink]);

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: links
});

const useStyles = makeStyles(() => ({
  snackbar: {
    bottom: 80
  }
}));

function App() {
  const classes = useStyles();

  return (
    <ApolloProvider client={client}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider
          maxSnack={1}
          autoHideDuration={2000}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          classes={{
            containerAnchorOriginBottomCenter: classes.snackbar
          }}
        >
          <Router>
            <Route exact path="/" component={RecipeListPage} />
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/create" component={CreateRecipePage} />
            <Route exact path="/recipe/:id" component={ViewRecipePage} />
            <Route exact path="/recipe/:id/edit" component={EditRecipePage} />
            <Route
              exact
              path="/recipe/:id/history"
              component={ViewRecipeHistoryPage}
            />
            <Route exact path="/recents" component={RecentsPage} />
            <Route exact path="/search" component={SearchPage} />
            <Route
              exact
              path="/meat-temperatures"
              component={MeatTemperaturePage}
            />
            <Route exact path="/test" component={TestPage} />
          </Router>
        </SnackbarProvider>
      </MuiThemeProvider>
    </ApolloProvider>
  );
}

export default App;
