import React from 'react';
import MuiCheckbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

const SmallCheckBoxIcon = withStyles({
  root: {
    fontSize: '18px'
  }
})(CheckBoxIcon);

const SmallCheckBoxOutlineBlankIcon = withStyles({
  root: {
    fontSize: '18px'
  }
})(CheckBoxOutlineBlankIcon);

const Checkbox = (props: CheckboxProps) => {
  return (
    <MuiCheckbox
      icon={<SmallCheckBoxOutlineBlankIcon />}
      checkedIcon={<SmallCheckBoxIcon />}
      {...props}
    />
  );
};

export default withStyles({
  root: {
    fontSize: '18px',
    padding: '0 4px 0 0'
  }
})(Checkbox);
