import React from 'react';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';

const RawLogo = createSvgIcon(
  <React.Fragment>
    <g>
      <g>
        <path
          fill="#FCB22B"
          d="M197.29,154.51V59.49c0-2.86-1.52-5.5-4-6.93L111,5.05c-2.48-1.43-5.52-1.43-8,0L20.71,52.56
                c-2.48,1.43-4,4.07-4,6.93v95.02c0,2.86,1.52,5.5,4,6.93L103,208.95c2.48,1.43,5.52,1.43,8,0l82.29-47.51
                C195.77,160.01,197.29,157.37,197.29,154.51z"
        />
        <path
          d="M107,213c-1.88,0-3.77-0.48-5.45-1.45l-82.37-47.56c-3.36-1.94-5.45-5.55-5.45-9.43V59.44c0-3.88,2.09-7.49,5.45-9.43
                l82.37-47.56c3.36-1.94,7.53-1.94,10.89,0l82.37,47.56c3.36,1.94,5.45,5.55,5.45,9.43v95.11c0,3.88-2.09,7.49-5.45,9.43
                l-82.37,47.56C110.77,212.52,108.88,213,107,213z M107,6.94c-0.86,0-1.71,0.22-2.48,0.66L22.16,55.16
                c-1.53,0.88-2.48,2.52-2.48,4.29v95.11c0,1.76,0.95,3.41,2.48,4.29l82.37,47.56c1.53,0.88,3.42,0.88,4.95,0l82.37-47.56
                c1.53-0.88,2.48-2.52,2.48-4.29V59.44c0-1.76-0.95-3.41-2.48-4.29L109.48,7.6C108.71,7.16,107.86,6.94,107,6.94z"
        />
      </g>
      <g>
        <g>
          <path
            fill="#895724"
            d="M174.96,134.17l-2.72-4.75c-3.12-4.61,3.34-9.66,0.21-14.68l-13.63-23.2c-0.46-0.78-0.33-1.77,0.31-2.41
                    c2.92-2.92,5.67-5.67,5.7-5.7c14.65-16.86-8.18-37.28-23.3-23.3c-0.03,0.03-2.21,2.21-4.75,4.74c-0.32,0.32-0.82,0.38-1.21,0.14
                    c-7.29-4.38-17.98-7.03-27.98-7.03c-11.16,0-22.31,3.12-30.14,8.43c-0.39,0.27-0.91,0.22-1.25-0.11
                    c-3.09-3.09-6.14-6.14-6.18-6.17c-16.86-14.65-37.28,8.18-23.3,23.3c0.04,0.04,3.9,3.9,7.33,7.33c0.64,0.64,0.76,1.62,0.3,2.4
                    l-12.72,21.58c-3.22,4.59,3.23,9.65,0.21,14.68l-2.72,4.75c-1.3,2.27-1.25,5.08,0.13,7.3c8.06,14.31,41.49,24.63,67.79,24.63
                    s60.68-11.89,67.79-24.63C176.21,139.25,176.26,136.44,174.96,134.17z"
          />
          <path
            d="M107.04,169.07c-26.85,0-61.49-10.48-70.35-26.09c-1.92-3.15-1.98-7.08-0.15-10.29l2.72-4.75
                    c0.8-1.33,0.39-2.72-0.41-5.06c-0.89-2.64-2.11-6.24,0.27-9.74L51.4,92.31l-0.51-0.51c-3.13-3.13-6.27-6.27-6.3-6.3
                    c-8.37-9.06-6.2-20.48,0.23-27.11c6.11-6.3,17.22-9.15,27.15-0.51l0.12,0.11c0.02,0.02,0.06,0.06,5.07,5.07
                    c8.26-5.13,19.24-8.04,30.43-8.04c9.95,0,20.57,2.54,28.16,6.68l0.57-0.57c1.79-1.79,3.12-3.12,3.14-3.14
                    c9.05-8.36,20.47-6.2,27.1,0.24c6.3,6.11,9.15,17.22,0.51,27.15l-0.13,0.14c-0.03,0.03-2.46,2.46-5.17,5.18l13.24,22.54
                    c2.28,3.66,1.04,7.33,0.13,10.01c-0.83,2.45-1.1,3.54-0.44,4.51l0.12,0.19l2.72,4.75c1.83,3.2,1.77,7.14-0.15,10.28
                    C169.11,157.68,132.8,169.07,107.04,169.07z M48.86,81.37c0.36,0.37,3.3,3.3,6.23,6.23l1.06,1.06c1.6,1.59,1.91,4.07,0.76,6.01
                    l-12.85,21.77c-0.68,0.97-0.41,2.07,0.42,4.54c0.89,2.65,2.12,6.28-0.1,9.97l-2.68,4.7c-0.76,1.33-0.73,2.96,0.08,4.26l0.06,0.11
                    c6.82,12.1,37.89,23.12,65.2,23.12c25.9,0,58.81-11.66,65.19-23.11l0.07-0.12c0.81-1.3,0.84-2.93,0.08-4.26l-2.67-4.66
                    c-2.28-3.48-1.08-7.04-0.2-9.64c0.87-2.56,1.17-3.81,0.41-5.03l-13.67-23.27c-1.14-1.95-0.83-4.42,0.77-6.01
                    c2.65-2.65,5.17-5.17,5.62-5.62c6.47-7.52,3.93-14.88-0.23-18.91c-4.47-4.34-12.27-6.25-18.84-0.22
                    c-0.23,0.23-1.46,1.45-3.06,3.06l-1.64,1.64c-1.29,1.29-3.28,1.53-4.84,0.59c-6.78-4.07-16.91-6.6-26.45-6.6
                    c-10.51,0-21.15,2.96-28.47,7.92c-1.57,1.06-3.68,0.87-5.02-0.47c-2.83-2.82-5.62-5.62-6.1-6.1c-7.52-6.47-14.88-3.93-18.92,0.23
                    C44.74,67,42.83,74.8,48.86,81.37z"
          />
        </g>
        <g>
          <g>
            <circle cx="128.55" cy="100.12" r="4.79" />
          </g>
          <g>
            <circle cx="85.53" cy="100.12" r="4.79" />
          </g>
        </g>
        <g>
          <path
            fill="#E5AD76"
            d="M102.97,98.22l-10.02,13.2c-1.7,1.84-2.62,4.08-2.62,6.37v17.2c0,8.74,7.48,15.83,16.71,15.83
                    c9.23,0,16.71-7.09,16.71-15.83v-17.13c0-2.34-0.96-4.62-2.72-6.48l-10.28-13.2C108.78,96.1,104.91,96.12,102.97,98.22z"
          />
          <path
            d="M107.04,153.8c-10.85,0-19.69-8.44-19.69-18.8v-17.2c0-2.99,1.17-5.92,3.31-8.28l10.12-13.31
                    c1.48-1.6,3.68-2.52,6.04-2.54c2.36,0.01,4.58,0.89,6.09,2.47l0.19,0.22l10.2,13.1c2.21,2.38,3.43,5.36,3.43,8.41v17.13
                    C126.72,145.36,117.89,153.8,107.04,153.8z M105.22,100.16l-10.09,13.27c-1.2,1.29-1.83,2.8-1.83,4.35v17.2
                    c0,7.09,6.16,12.86,13.74,12.86s13.74-5.77,13.74-12.86v-17.13c0-1.59-0.66-3.12-1.9-4.43l-0.19-0.22l-10.17-13.06
                    c-0.37-0.34-0.99-0.55-1.64-0.55c-0.01,0-0.01,0-0.02,0C106.2,99.61,105.59,99.82,105.22,100.16z"
          />
        </g>
        <path
          d="M109.34,131.33l4.91-5.89c1.63-1.95,0.24-4.92-2.31-4.92h-9.81c-2.54,0-3.93,2.97-2.31,4.92l4.91,5.89
                C105.93,132.77,108.14,132.77,109.34,131.33z"
        />
      </g>
    </g>
  </React.Fragment>,
  'Logo'
);

const Logo = (props: any) => (
  <RawLogo
    version="1.1"
    x="0px"
    y="0px"
    width={214}
    height={214}
    enableBackground="new 0 0 214 214"
    viewBox="0 0 214 214"
    {...props}
  />
);

export default Logo;
