import { createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';

const fontFamily = ['Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(
  ','
);

const defaultTheme = createMuiTheme();

const theme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: {
      main: '#FCB22B'
    }
  },
  typography: {
    fontFamily,
    h1: {
      color: '#344146',
      fontFamily: 'Roboto',
      fontSize: '28px',
      fontWeight: 300,
      lineHeight: '33px'
    },
    h2: {
      color: '#76888C',
      fontSize: '18px',
      fontWeight: 500,
      textTransform: 'uppercase',
      letterSpacing: '1.4px',
      lineHeight: '22px'
    },
    h3: {
      color: '#76888C',
      fontSize: '16px',
      fontWeight: 500,
      textTransform: 'uppercase',
      letterSpacing: '1.4px',
      lineHeight: '20px'
    },
    h6: {
      color: '#344146',
      fontFamily: 'Roboto',
      fontSize: '20px',
      fontWeight: 'normal',
      letterSpacing: 0,
      lineHeight: '24px'
    }
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: '#FFF',
          fontFamily
        }
      }
    },
    MuiAppBar: {
      colorDefault: {
        backgroundColor: '#FFF'
      }
    },
    MuiToolbar: {
      gutters: {
        paddingLeft: 10,
        paddingRight: 10,
        [defaultTheme.breakpoints.up('sm')]: {
          paddingLeft: 10,
          paddingRight: 10
        }
      }
    }
  }
});

export default theme;
