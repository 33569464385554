import React from 'react';
import { History } from 'history';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12
  },
  sectionDesktop: {
    display: 'flex'
  }
}));

interface Props {
  // non-style props
  id: string;
  title: string;
  history: History;
}

interface BackButtonProps {
  id: string;
  className: string;
  history: History;
}

function BackButton({ id, className, history }: BackButtonProps) {
  const { state } = history.location;

  if (state && state.useHistoryBack) {
    return (
      <IconButton className={className} onClick={() => history.goBack()}>
        <ArrowBackIcon />
      </IconButton>
    );
  }

  return (
    <IconButton
      className={className}
      component={RouterLink}
      to={`/recipe/${id}`}
    >
      <ArrowBackIcon />
    </IconButton>
  );
}

function ViewRecipeHistoryAppBar({ id, title, history }: Props) {
  const classes = useStyles();

  return (
    <AppBar position="sticky" color="default" elevation={1}>
      <Toolbar>
        <BackButton id={id} className={classes.menuButton} history={history} />
        <Typography
          className={classes.grow}
          color="inherit"
          variant="h6"
          noWrap
        >
          {title}
        </Typography>
      </Toolbar>
    </AppBar>
  );
}

export default ViewRecipeHistoryAppBar;
