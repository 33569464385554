import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import ClearIcon from '@material-ui/icons/Clear';
import SmallLogo from 'icons/SmallLogo';

const useStyles = makeStyles(theme => ({
  toolbar: {
    justifyContent: 'flex-end'
  },
  search: {
    position: 'relative',
    borderWidth: 1,
    borderColor: '#82CBED',
    borderRadius: 10,
    borderStyle: 'solid',
    backgroundColor: fade('#7FCAEC', 0.15),
    '&:hover': {
      backgroundColor: fade('#7FCAEC', 0.1)
    },
    marginRight: 0,
    marginLeft: 0,
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  searchIcon: {
    color: '#82CBED',
    width: theme.spacing(5),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  clearSearchIcon: {
    color: '#76888C',
    width: theme.spacing(5),
    height: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    right: 0,
    top: 0,
    padding: 0
  },
  inputRoot: {
    color: 'inherit',
    width: '100%'
  },
  inputInput: {
    padding: theme.spacing(1, 5, 1, 2),
    transition: theme.transitions.create('width'),
    width: '100%'
  }
}));

interface Props {
  searchQuery: string;
  onSearchChange: React.EventHandler<React.ChangeEvent<HTMLInputElement>>;
  onSearchClear: () => void;
}

function SearchAppBar(props: Props) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const { searchQuery, onSearchChange, onSearchClear } = props;
  const classes = useStyles();

  return (
    <AppBar position="sticky" color="default" elevation={1}>
      <Toolbar className={classes.toolbar}>
        <IconButton color="inherit" component={RouterLink} to="/">
          <SmallLogo />
        </IconButton>
        <div className={`${classes.search}`}>
          <InputBase
            placeholder="Search…"
            classes={{
              root: `${classes.inputRoot}`,
              input: `${classes.inputInput}`
            }}
            inputProps={{ 'aria-label': 'search' }}
            onChange={onSearchChange}
            value={searchQuery}
            autoFocus
            inputRef={inputRef}
          />
          <IconButton
            className={classes.clearSearchIcon}
            onClick={() => {
              onSearchClear();

              if (inputRef && inputRef.current) {
                inputRef.current.focus();
              }
            }}
          >
            <ClearIcon />
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default SearchAppBar;
