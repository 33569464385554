import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    // 72px to give the nav menu room to breathe
    margin: '0 auto 72px auto',
    padding: 8,
    maxWidth: 800
  }
}));

interface Props {
  children?: React.ReactNode;
  className?: string;
}

function Content({ children, className }: Props) {
  const classes = useStyles();
  return <div className={`${classes.root} ${className || ''}`}>{children}</div>;
}

export default Content;
