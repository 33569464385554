import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SaveButton from 'components/SaveButton';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12
  },
  sectionDesktop: {
    display: 'flex'
  }
}));

interface Props {
  // non-style props
  id?: string;
  title: string;
  disableSubmit: boolean;
}

function CreateRecipeAppBar({ disableSubmit, id, title }: Props) {
  const classes = useStyles();

  return (
    <AppBar position="sticky" color="default" elevation={1}>
      <Toolbar>
        <IconButton
          className={classes.menuButton}
          component={RouterLink}
          to="/"
        >
          <CloseIcon />
        </IconButton>
        <Typography
          className={classes.grow}
          color="inherit"
          variant="h6"
          noWrap
        >
          {title}
        </Typography>
        <div className={classes.sectionDesktop}>
          <SaveButton color="inherit" type="submit" disabled={disableSubmit}>
            Save
          </SaveButton>
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default CreateRecipeAppBar;
