import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import IconAdd from '@material-ui/icons/Add';
import UploadFileList from 'components/UploadFileList';
import { ImageType } from 'types/recipes';

type Props = {
  classes: {
    root: string;
    label: string;
    uploadInput: string;
  };
  className: string;
  onCompleted: (images: ImageType[]) => void;
};

const ImagePlaceholder = withStyles({
  root: {
    width: '40px',
    height: '40px'
  }
})(IconAdd);

const ImageUploadButton = ({ className, classes, onCompleted }: Props) => {
  return (
    <div className={`${className} ${classes.root}`}>
      <label htmlFor="input-upload" className={classes.label}>
        <ImagePlaceholder color="action" />
      </label>
      <UploadFileList
        className={classes.uploadInput}
        onCompleted={onCompleted}
      />
    </div>
  );
};

export default withStyles({
  root: {
    position: 'relative',
    width: '80px',
    height: '80px',
    overflow: 'hidden',
    display: 'inline-block'
  },
  label: {
    display: 'flex',
    border: '1px dashed #888',
    borderRadius: '4px',
    backgroundColor: '#F4F5F5',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
    width: '80px',
    height: '80px'
  },
  uploadInput: {
    cursor: 'pointer',
    position: 'absolute',
    left: 0,
    top: 0,
    opacity: 0,
    width: 1,
    height: 1
  }
})(ImageUploadButton);
