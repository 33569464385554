import React, { useEffect } from 'react';
import { RouteComponentProps, Link as RouterLink } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import debounce from 'lodash/debounce';
import qs from 'query-string';

import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { makeStyles } from '@material-ui/core/styles';

import noSleep from 'utils/noSleep';

import Page from 'components/Page';
import Content from 'components/Content';
import NavMenuDefault from 'components/NavMenuDefault';
import WarningNotificationTooltipButton from 'components/WarningNotificationTooltipButton';
import { RecipeType } from 'types/recipes';
import { SEARCH_RECIPES } from 'queries';

import SearchAppBar from './SearchAppBar';
import SearchIngredientsToggle from './SearchIngredientsToggle';

type TParams = { q: string };

const useStyles = makeStyles(({ typography }) => ({
  header: {
    color: 'inherit',
    fontSize: typography.pxToRem(15),
    fontWeight: typography.fontWeightMedium,
    textTransform: 'uppercase'
  },
  list: {
    flexGrow: 1
  },
  listItem: {
    '&:nth-child(odd)': {
      backgroundColor: '#F4F5F5'
    }
  },
  searchList: {
    flexGrow: 1,
    marginTop: 10
  },
  searchPlaceholderContent: {
    marginTop: 10
  }
}));

type Response = {
  searchRecipes: RecipeType[];
};

type Variables = {
  query: string;
  ingredients: boolean;
};

const title = 'Search';

type SearchRecipesListContent = {
  searchQuery: string;
  ingredients: boolean;
};

function SearchRecipesListContent({
  searchQuery,
  ingredients
}: SearchRecipesListContent) {
  const [debouncedSearchQuery, setDebouncedSearchQuery] = React.useState(
    searchQuery
  );
  const delayedSetDebouncedQuery = React.useRef(
    debounce((q: string) => {
      setDebouncedSearchQuery(q);
    }, 500)
  ).current;
  const classes = useStyles();

  // Clean up debounce function
  useEffect(() => {
    return () => {
      delayedSetDebouncedQuery.cancel();
    };
  }, [delayedSetDebouncedQuery]);

  if (debouncedSearchQuery !== searchQuery) {
    delayedSetDebouncedQuery(searchQuery);
  }

  const { loading, error, data } = useQuery<Response, Variables>(
    SEARCH_RECIPES,
    {
      variables: { query: debouncedSearchQuery, ingredients: ingredients },
      fetchPolicy: 'cache-and-network'
    }
  );

  // Cache last results
  if (loading && !error && !data) {
    return <Typography variant="h1">Search Results</Typography>;
  }

  const recipes = (data && data.searchRecipes) || [];
  return (
    <>
      <Typography variant="h1">Search Results ({recipes.length})</Typography>
      {error && (
        <div className={classes.searchPlaceholderContent}>
          Error encountered during search...
        </div>
      )}
      {recipes && recipes.length > 0 && (
        <List className={classes.searchList} dense disablePadding>
          {recipes.map((recipe: RecipeType) => (
            <ListItem
              classes={{
                root: !recipe.warning ? classes.listItem : undefined,
                container: classes.listItem
              }}
              button
              component={RouterLink}
              to={{
                pathname: `/recipe/${recipe._id}`,
                state: {
                  useHistoryBack: true
                }
              }}
              onClick={() => {
                noSleep.enable();
              }}
              key={recipe._id}
            >
              <ListItemText primary={recipe.name} />
              {recipe.warning && (
                <ListItemSecondaryAction>
                  <WarningNotificationTooltipButton warning={recipe.warning} />
                </ListItemSecondaryAction>
              )}
            </ListItem>
          ))}
        </List>
      )}
    </>
  );
}

function SearchPlaceholderContent() {
  const classes = useStyles();
  return (
    <>
      <Typography variant="h1">Search Results</Typography>
      <div className={classes.searchPlaceholderContent}>
        Please type 3 characters or more to begin search
      </div>
    </>
  );
}

function RecipeListPage({ history, location }: RouteComponentProps<TParams>) {
  const queryParams = qs.parse(location.search);
  const searchQuery = queryParams.q ? queryParams.q.toString() : '';
  const includeIngredients = queryParams.ingredients === '1' ? true : false;
  const isSearching = searchQuery && searchQuery.length >= 3;

  const handleSearch = React.useCallback(
    (query, shouldIncludeIngredients) => {
      history.replace({
        ...location,
        search: qs.stringify({
          q: query,
          ingredients: shouldIncludeIngredients ? '1' : undefined
        })
      });
    },
    [history, location]
  );

  return (
    <Page title={title}>
      <SearchAppBar
        searchQuery={searchQuery}
        onSearchChange={event => {
          const searchQueryValue = event.target.value;
          handleSearch(searchQueryValue, includeIngredients);
        }}
        onSearchClear={() => {
          handleSearch('', includeIngredients);
        }}
      />
      <Content>
        <SearchIngredientsToggle
          checked={includeIngredients}
          onChange={() => {
            handleSearch(searchQuery, !includeIngredients);
          }}
        />
        {!isSearching ? (
          <SearchPlaceholderContent />
        ) : (
          <SearchRecipesListContent
            searchQuery={searchQuery}
            ingredients={includeIngredients}
          />
        )}
      </Content>
      <NavMenuDefault />
    </Page>
  );
}

export default RecipeListPage;
