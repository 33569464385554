import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useQuery, useMutation } from '@apollo/client';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';

import Page from 'components/Page';
import Content from 'components/Content';
import ScrollToTopOnMount from 'components/ScrollToTopOnMount';

import { RecipeType } from 'types/recipes';
import { GET_CREATION_EVENTS, DELETE_CREATION_EVENT } from 'queries';

import ViewRecipeHistoryAppBar from './ViewRecipeHistoryAppBar';
import ViewRecipeHistoryNavMenu from './ViewRecipeHistoryNavMenu';
import { IconButton } from '@material-ui/core';

type TParams = { id: string };

const useStyles = makeStyles(({ typography, palette }) => ({
  grower: {
    flexGrow: 1
  },
  panel: {
    boxShadow: 'none',
    marginTop: 0
  },
  header: {
    color: palette.text.secondary,
    fontSize: typography.pxToRem(15),
    fontWeight: typography.fontWeightMedium,
    textTransform: 'uppercase'
  },
  table: {
    marginTop: 10
  },
  tableHeader: {
    backgroundColor: '#E3E3E3',
    fontWeight: 'bold'
  },
  tableHeaderColSmall: {
    width: 36
  },
  tableRow: {
    '&:nth-child(odd)': {
      backgroundColor: '#F4F5F5'
    }
  }
}));

type GetResponse = {
  recipe: RecipeType;
};

type GetVariables = {
  id: string;
};

type DeleteResponse = {
  recipe: RecipeType;
};

type DeleteVariables = {
  id: string;
  date: string;
};

type RecipeHistoryProps = {
  recipeId: string;
  recipe: RecipeType;
};

function RecipeHistory({ recipeId, recipe }: RecipeHistoryProps) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [deleteCreationEvent] = useMutation<DeleteResponse, DeleteVariables>(
    DELETE_CREATION_EVENT,
    {
      onCompleted: () => {
        enqueueSnackbar('I did not make dis.', {
          variant: 'success'
        });
      },
      onError: () => {
        enqueueSnackbar('Unable to delete event.', { variant: 'error' });
      }
    }
  );

  // Since order is not guaranteed, make sure to sort here
  const creationEvents = recipe.creationEvents
    ? [...recipe.creationEvents]
    : [];
  if (creationEvents && creationEvents.length > 0) {
    creationEvents.sort(
      (a, b): number => {
        // Super inefficient but whatevs
        const aDate = new Date(a.date).getTime();
        const bDate = new Date(b.date).getTime();
        return bDate - aDate;
      }
    );
  }

  return (
    <Content>
      <ScrollToTopOnMount />
      <Typography color="inherit" variant="h1">
        History for {recipe.name}
      </Typography>

      <TableContainer>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow className={classes.tableHeader}>
              <TableCell>Date</TableCell>
              <TableCell>Time</TableCell>
              <TableCell className={classes.tableHeaderColSmall}>Del</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {creationEvents &&
              creationEvents.length > 0 &&
              creationEvents.map((creationEvent, index) => {
                const date = new Date(creationEvent.date);
                const readableDate = date.toLocaleDateString();
                const readableTime = date.toLocaleTimeString();
                return (
                  <TableRow key={index} className={classes.tableRow}>
                    <TableCell>{readableDate}</TableCell>
                    <TableCell>{readableTime}</TableCell>
                    <TableCell>
                      <IconButton
                        color="inherit"
                        size="small"
                        onClick={() =>
                          deleteCreationEvent({
                            variables: {
                              id: recipeId,
                              date: creationEvent.date
                            }
                          })
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            {creationEvents && creationEvents.length === 0 && (
              <TableRow className={classes.tableRow}>
                <TableCell colSpan={3}>No history.</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Content>
  );
}

function ViewRecipeHistoryPage({
  history,
  match
}: RouteComponentProps<TParams>) {
  const recipeId = match.params.id;
  const { loading, error, data } = useQuery<GetResponse, GetVariables>(
    GET_CREATION_EVENTS,
    {
      variables: { id: recipeId },
      fetchPolicy: 'cache-and-network'
    }
  );

  let title = 'Recipe Name';
  if (error) {
    title = 'Unable to load recipe.';
  } else if (data && data.recipe && data.recipe.name) {
    title = data.recipe.name;
  } else if (loading) {
    title = 'Loading...';
  }

  return (
    <Page title={title}>
      <ViewRecipeHistoryAppBar title={title} id={recipeId} history={history} />
      {!error && data && data.recipe && (
        <RecipeHistory recipeId={recipeId} recipe={data.recipe} />
      )}
      <ViewRecipeHistoryNavMenu recipeId={recipeId} />
    </Page>
  );
}

export default ViewRecipeHistoryPage;
