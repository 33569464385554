import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { MeatTemperatureCollectionType } from 'types/meatTemperatures';
import MeatTemperature from 'components/MeatTemperature/MeatTemperature';

const useStyles = makeStyles(() => {
  return {
    icon: {
      width: 24,
      height: 24,
      marginRight: 8
    },
    title: {
      fontSize: 18,
      lineHeight: '24px',
      fontWeight: 500,
      display: 'flex',
      marginBottom: 8
    },
    temperatures: {
      display: 'flex',
      maxWidth: 390
    },
    temperature: {
      flexBasis: '33%'
    }
  };
});

interface Props {
  className?: string;
  meatTemperatureCollection: MeatTemperatureCollectionType;
}

const MeatTemperatureCollection = ({
  className = '',
  meatTemperatureCollection
}: Props) => {
  const classes = useStyles();

  const MeatTemperatureIcon = meatTemperatureCollection.icon;

  return (
    <div className={className}>
      <div className={classes.title}>
        <MeatTemperatureIcon className={classes.icon} />{' '}
        {meatTemperatureCollection.name}
      </div>
      <div className={classes.temperatures}>
        {meatTemperatureCollection.temperatures.map(temperature => (
          <MeatTemperature
            key={temperature.name}
            className={classes.temperature}
            meatTemperature={temperature}
          />
        ))}
      </div>
    </div>
  );
};

export default MeatTemperatureCollection;
