import React from 'react';
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const createInputClasses = makeStyles({
  inputMultiline: {
    padding: 0
  }
});
const createFormHelperTextClasses = makeStyles({
  root: {
    marginTop: '4px',
    fontSize: '0.6rem'
  }
});

interface CustomProps {
  onEnterKey(e: any): void;
}

const TextField = ({
  onKeyPress,
  onEnterKey,
  ...rest
}: TextFieldProps & CustomProps) => {
  const inputClasses = createInputClasses();
  const formHelperTextClasses = createFormHelperTextClasses();

  return (
    <MuiTextField
      InputProps={{ classes: inputClasses }}
      FormHelperTextProps={{ classes: formHelperTextClasses }}
      onKeyPress={e => {
        // Override default Enter behavior to avoid submit
        if (
          (!rest.multiline && e.key === 'Enter') ||
          (rest.multiline && !e.shiftKey && e.key === 'Enter')
        ) {
          // Prevent default for single line always.
          // Prevent default for multiline only if onEnterKey is passed
          if (!rest.multiline || (rest.multiline && onEnterKey)) {
            e.preventDefault();
          }
          if (onEnterKey) {
            onEnterKey(e);
          }
        }
      }}
      {...rest}
    />
  );
};

export default TextField;
