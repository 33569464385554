import React from 'react';
import { RouteComponentProps, Prompt } from 'react-router-dom';
import { Formik, Form, FormikActions } from 'formik';
import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/client';

import Page from 'components/Page';
import Content from 'components/Content';

import { RecipeType, RecipeInputType } from 'types/recipes';
import { ADD_RECIPE } from 'queries';

import CreateRecipeAppBar from './CreateRecipeAppBar';
import RecipeEditForm from 'components/RecipeEditForm';
import formatRecipeInput from 'utils/formatRecipeInput';
import ScrollToTopOnMount from 'components/ScrollToTopOnMount';

type TParams = {};

type Response = {
  addRecipe: RecipeType;
};

type Variables = {
  input: RecipeInputType;
};

const title = 'Create New Recipe';

function CreateRecipePage({ history }: RouteComponentProps<TParams>) {
  const { enqueueSnackbar } = useSnackbar();

  const [addRecipe] = useMutation<Response, Variables>(ADD_RECIPE, {
    onCompleted: ({ addRecipe }) => {
      const { _id } = addRecipe;
      enqueueSnackbar('Recipe created successfully.', { variant: 'success' });
      history.push(`/recipe/${_id}`);
    },
    onError: () => {
      enqueueSnackbar('Recipe creation unsuccessful.', { variant: 'error' });
    }
  });

  return (
    <Page title={title}>
      <ScrollToTopOnMount />
      <Formik
        initialValues={{
          name: '',
          tags: [],
          images: [],
          servingSize: '',
          prepTime: '',
          cookTime: '',
          ingredientLists: [
            {
              title: '',
              ingredients: [{ name: '', quantity: '', type: '' }]
            }
          ],
          directionLists: [
            {
              title: '',
              directions: [{ text: '' }]
            }
          ],
          warning: '',
          notes: '',
          source: ''
        }}
        onSubmit={(
          values: RecipeType,
          { setSubmitting }: FormikActions<RecipeType>
        ) => {
          setTimeout(() => {
            const formattedValues = formatRecipeInput(values);
            addRecipe({ variables: { input: formattedValues } });
            setSubmitting(false);
          }, 500);
        }}
        render={formikProps => (
          <Form noValidate autoComplete="off">
            <Prompt
              when={formikProps.dirty && formikProps.submitCount === 0}
              message="Are you sure you want to leave? You have with unsaved changes."
            />
            <CreateRecipeAppBar
              title={title}
              disableSubmit={formikProps.isSubmitting}
            />
            <Content>
              <RecipeEditForm {...formikProps} autoFocusName />
            </Content>
          </Form>
        )}
      />
    </Page>
  );
}

export default CreateRecipePage;
