import React from 'react';
import { DragDropContext, Droppable, DragUpdate } from 'react-beautiful-dnd';
import { FieldArray } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import { ImageType } from 'types/recipes';
import ImageUploadButton from 'components/ImageUploadButton';
import ImageThumbnailEdit from 'components/ImageThumbnailEdit';

type Props = {
  classes: {
    root: string;
    thumbnail: string;
    thumbnailList: string;
  };
  images: ImageType[];
};

const ImageThumbnailListEdit = ({ classes, images }: Props) => {
  return (
    <div className={classes.root}>
      <FieldArray
        name="images"
        render={arrayHelpers => {
          function onDragEnd(result: DragUpdate) {
            // dropped outside the list
            if (!result.destination) {
              return;
            }

            if (result.destination.index === result.source.index) {
              return;
            }

            arrayHelpers.move(result.source.index, result.destination.index);
          }

          return (
            <>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable
                  droppableId={`thumbnail-droppable`}
                  direction="horizontal"
                >
                  {provided => (
                    <div
                      ref={provided.innerRef}
                      className={classes.thumbnailList}
                      {...provided.droppableProps}
                    >
                      {images.map((image, index) => (
                        <ImageThumbnailEdit
                          className={classes.thumbnail}
                          onDelete={() => {
                            arrayHelpers.remove(index);
                          }}
                          key={index}
                          image={image}
                          index={index}
                        />
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              <ImageUploadButton
                className={classes.thumbnail}
                onCompleted={(images: ImageType[]) => {
                  images.forEach(image => {
                    arrayHelpers.push(image);
                  });
                }}
              />
            </>
          );
        }}
      />
    </div>
  );
};

export default withStyles({
  root: {
    whiteSpace: 'nowrap',
    overflowX: 'auto',
    overflowY: 'hidden',
    height: '80px',
    width: '100%',
    marginBottom: 8
  },
  thumbnail: {
    display: 'inline-block',
    marginRight: '16px'
  },
  thumbnailList: {
    display: 'inline-block'
  }
})(ImageThumbnailListEdit);
