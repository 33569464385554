import React from 'react';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';

export default createSvgIcon(
  <React.Fragment>
    <path
      d="M6.73403 23.996C6.08903 23.996 5.52003 23.585 5.31703 22.974C5.30803 22.948 5.30203 22.923 5.29603 22.898L4.53003 19.466L1.09503 18.7C1.07103 18.695 1.04703 18.688 1.02303 18.68C0.645025 18.555 0.338025 18.29 0.159025 17.935C-0.210975 17.2 0.0860251 16.301 0.821025 15.931L6.06503 13.597C6.18103 12.984 6.31703 12.372 6.46903 11.773L4.22603 9.53095C3.56302 8.89595 3.37003 7.89595 3.75303 7.04795C4.92103 4.64795 8.09602 2.92395 11.511 2.81495C13.733 0.947953 16.549 0.00195312 19.885 0.00195312C20.545 0.00195312 21.235 0.0389531 21.936 0.113953C22.969 0.230953 23.769 1.03095 23.886 2.05895C24.336 6.31395 23.427 9.82095 21.185 12.489C21.076 15.904 19.356 19.077 16.972 20.238C16.671 20.374 16.363 20.44 16.047 20.44C15.449 20.44 14.87 20.193 14.458 19.764L12.23 17.531C11.63 17.683 11.017 17.819 10.404 17.935L8.08202 23.143C7.89102 23.524 7.58203 23.795 7.20403 23.92C7.05103 23.971 6.89303 23.996 6.73403 23.996ZM5.32203 18.106C5.60503 18.169 5.82802 18.393 5.89103 18.675L6.73803 22.473L8.98403 17.434L6.56603 15.016L1.52503 17.259L5.32203 18.106ZM15.529 18.714C15.669 18.86 15.857 18.941 16.049 18.941C16.148 18.941 16.245 18.92 16.335 18.879C17.762 18.184 18.975 16.32 19.462 14.162C17.964 15.364 16.091 16.334 13.875 17.056L15.529 18.714ZM10.129 16.458C19.088 14.766 23.215 9.97695 22.395 2.22195C22.358 1.89695 22.102 1.64195 21.772 1.60395C21.128 1.53595 20.492 1.50095 19.887 1.50095C13.246 1.50095 9.09303 5.66195 7.54203 13.87L10.129 16.458ZM9.83702 4.53695C7.67902 5.02495 5.81303 6.24295 5.11103 7.68395C4.99803 7.93395 5.06403 8.25695 5.27503 8.45895L6.94303 10.126C7.66403 7.90895 8.63503 6.03495 9.83702 4.53695Z"
      fill="black"
    />
    <path
      d="M16.874 6.00098C17.4953 6.00098 17.999 5.4973 17.999 4.87598C17.999 4.25466 17.4953 3.75098 16.874 3.75098C16.2527 3.75098 15.749 4.25466 15.749 4.87598C15.749 5.4973 16.2527 6.00098 16.874 6.00098Z"
      fill="black"
    />
  </React.Fragment>,
  'FishIcon'
);
