import React, { useMemo } from 'react';
import { Typography } from '@material-ui/core';

import Checklist from 'components/Checklist';
import { IngredientListType, IngredientType } from 'types/recipes';
import formatMeasurements from 'utils/formatMeasurements';

interface Props {
  className?: string;
  storeKey?: string;
  showTitle: boolean;
  ingredientList: IngredientListType;
}

const formatIngredientItems = (ingredients: IngredientType[]) => {
  return ingredients.map(ingredient => {
    const typeText = ingredient.type ? ` ${ingredient.type}` : '';
    const ingredientQuantityText = ingredient.quantity
      ? ` (${ingredient.quantity}${typeText})`
      : '';
    return {
      text: formatMeasurements(`${ingredient.name}${ingredientQuantityText}`)
    };
  });
};

function IngredientList({
  className,
  showTitle,
  ingredientList,
  storeKey
}: Props) {
  const items = useMemo(
    () => formatIngredientItems(ingredientList.ingredients),
    [ingredientList]
  );

  return (
    <div className={className}>
      {showTitle && (
        <Typography variant="h3">{ingredientList.title}</Typography>
      )}
      <Checklist
        items={items}
        storeKey={storeKey ? `${storeKey}-ingredients` : null}
      />
    </div>
  );
}

export default IngredientList;
