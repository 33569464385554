import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from 'components/TextField';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DragUpdate
} from 'react-beautiful-dnd';
import { Field, FieldArray } from 'formik';
import { DirectionType, DirectionListType } from 'types/recipes';

interface Classes {
  root: string;
  row: string;
  direction: string;
  dragHandle: string;
  deleteButton: string;
  addButton: string;
  sectionTitle: string;
}

const SmallAddCircleIcon = withStyles({
  root: {
    width: '16px',
    height: '16px',
    marginRight: '4px'
  }
})(AddCircleIcon);

type DirectionProps = {
  classes: Classes;
  autoFocus?: boolean;
  direction: DirectionType;
  fieldPrefix: string;
  onChange(e: any): void;
  onBlur(e: any): void;
  onRemove(e: any): void;
  onEnterKey(e: any): void;
  index: number;
};

function Direction({
  classes,
  autoFocus,
  direction,
  fieldPrefix,
  onChange,
  onBlur,
  onRemove,
  onEnterKey,
  index
}: DirectionProps) {
  return (
    <Draggable draggableId={`${fieldPrefix}-draggable-${index}`} index={index}>
      {provided => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={classes.row}
        >
          <div {...provided.dragHandleProps} className={classes.dragHandle}>
            <DragHandleIcon color="action" />
          </div>
          <Field
            inputProps={{
              name: `${fieldPrefix}.directions.${index}.text`
            }}
            className={classes.direction}
            component={TextField}
            label={`Step ${index + 1}`}
            placeholder={`Step ${index + 1}`}
            value={direction.text}
            margin="dense"
            variant="outlined"
            onChange={onChange}
            onBlur={onBlur}
            onEnterKey={onEnterKey}
            multiline
            autoFocus={autoFocus}
          />
          <IconButton className={classes.deleteButton} onClick={onRemove}>
            <RemoveCircleIcon color="action" />
          </IconButton>
        </div>
      )}
    </Draggable>
  );
}

type Props = {
  classes: Classes;
  directionList: DirectionListType;
  onChange(e: any): void;
  onBlur(e: any): void;
  fieldPrefix: string;
  onSectionRemove(e: any): void;
  showSectionTitle: boolean;
};

const DirectionListEdit = ({
  classes,
  fieldPrefix,
  directionList,
  onChange,
  onBlur,
  onSectionRemove,
  showSectionTitle
}: Props) => {
  const [autoFocusIndex, setAutoFocusIndex] = useState(-1);

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <Field
          className={`${classes.sectionTitle} ${
            showSectionTitle ? 'is-visible' : ''
          }`}
          inputProps={{ name: `${fieldPrefix}.title` }}
          component={TextField}
          label="Section Title"
          placeholder="Section Title"
          margin="dense"
          variant="outlined"
          value={directionList.title}
          onChange={onChange}
          onBlur={onBlur}
        />
        {showSectionTitle && (
          <IconButton
            className={classes.deleteButton}
            onClick={onSectionRemove}
          >
            <DeleteIcon color="action" />
          </IconButton>
        )}
      </div>
      <FieldArray
        name={`${fieldPrefix}.directions`}
        render={arrayHelpers => {
          function onDragEnd(result: DragUpdate) {
            // dropped outside the list
            if (!result.destination) {
              return;
            }

            if (result.destination.index === result.source.index) {
              return;
            }

            arrayHelpers.move(result.source.index, result.destination.index);
          }
          return (
            <div>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={`${fieldPrefix}-droppable`}>
                  {provided => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {directionList.directions &&
                        directionList.directions.length > 0 &&
                        directionList.directions.map((direction, index) => (
                          <Direction
                            key={index}
                            classes={classes}
                            direction={direction}
                            fieldPrefix={fieldPrefix}
                            onChange={onChange}
                            onBlur={onBlur}
                            onRemove={() => arrayHelpers.remove(index)}
                            onEnterKey={() => {
                              arrayHelpers.insert(index + 1, { text: '' });
                              setAutoFocusIndex(index + 1);
                            }}
                            autoFocus={autoFocusIndex === index}
                            index={index}
                          />
                        ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              <Button
                color="primary"
                className={classes.addButton}
                onClick={() => {
                  arrayHelpers.push({ text: '' });
                  setAutoFocusIndex(directionList.directions.length);
                }}
              >
                <SmallAddCircleIcon color="primary" />
                Add Step
              </Button>
            </div>
          );
        }}
      />
    </div>
  );
};

export default withStyles({
  root: {},
  row: {
    display: 'flex',
    alignItems: 'start',
    '&:first-child': {
      marginTop: 4
    }
  },
  direction: {
    flex: 2,
    marginRight: '8px'
  },
  dragHandle: {
    marginTop: '8px',
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingRight: '8px'
  },
  addButton: {
    paddingLeft: 0
  },
  deleteButton: {
    marginTop: '8px'
  },
  sectionTitle: {
    display: 'none',
    flex: 1,
    '&.is-visible': {
      display: 'inline-flex'
    }
  }
})(DirectionListEdit);
