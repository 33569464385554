import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import AppBarDefault from 'components/AppBarDefault';
import Page from 'components/Page';
import Content from 'components/Content';
import NavMenuDefault from 'components/NavMenuDefault';
import BeefIcon from 'icons/BeefIcon';
import ChickenIcon from 'icons/ChickenIcon';
import PorkIcon from 'icons/PorkIcon';
import FishIcon from 'icons/FishIcon';
import LambIcon from 'icons/LambIcon';
import { MeatTemperatureCollectionType } from 'types/meatTemperatures';
import MeatTemperatureCollection from 'components/MeatTemperatureCollection';

const useStyles = makeStyles(({ palette, typography }) => ({
  header: {
    color: 'inherit',
    fontSize: typography.pxToRem(15),
    fontWeight: typography.fontWeightMedium,
    textTransform: 'uppercase'
  },
  list: {
    marginTop: 16
  },
  collection: {
    marginBottom: 16,
    '&:not(:first-child)': {
      paddingTop: 16,
      borderTopColor: palette.secondary.main,
      borderTopWidth: 2,
      borderTopStyle: 'solid'
    }
  }
}));

const title = 'Meat Temperatures';

export const meatTemperatureCollectionData: MeatTemperatureCollectionType[] = [
  {
    name: 'Beef',
    icon: BeefIcon,
    temperatures: [
      {
        name: 'burgers, rare',
        temperature: '120-124°F'
      },

      {
        name: 'steak, rare',
        temperature: '120-128°F'
      }
    ]
  },
  {
    name: 'Chicken',
    icon: ChickenIcon,
    temperatures: [
      {
        name: 'breast, sous vide',
        temperature: '140°F'
      },
      {
        name: 'breast, grilled',
        temperature: '150°F'
      },
      {
        name: 'drumstick / thighs',
        temperature: '160°F'
      }
    ]
  },
  {
    name: 'Pork',
    icon: PorkIcon,
    temperatures: [
      {
        name: 'pork chops',
        temperature: '120-130°F'
      },
      {
        name: 'sous vide',
        temperature: '140°F'
      }
    ]
  },
  {
    name: 'Salmon',
    icon: FishIcon,
    temperatures: [
      {
        name: 'grilled, baked',
        temperature: '110-120°F'
      }
    ]
  },
  {
    name: 'Lamb',
    icon: LambIcon,
    temperatures: [
      {
        name: 'oven roasted',
        temperature: '115-125°F'
      }
    ]
  }
];

function MeatTemperaturePage() {
  const classes = useStyles();
  return (
    <Page title={title}>
      <AppBarDefault />
      <Content>
        <Typography variant="h1">Meat Temperatures</Typography>
        <div className={classes.list}>
          {meatTemperatureCollectionData.map(meatTemperatureCollection => {
            return (
              <MeatTemperatureCollection
                key={meatTemperatureCollection.name}
                className={classes.collection}
                meatTemperatureCollection={meatTemperatureCollection}
              />
            );
          })}
        </div>
        <NavMenuDefault />
      </Content>
    </Page>
  );
}

export default MeatTemperaturePage;
