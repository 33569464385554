import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ReceiptIcon from '@material-ui/icons/Receipt';
import SearchIcon from '@material-ui/icons/Search';
import HistoryIcon from '@material-ui/icons/History';
import NavMenu from 'components/NavMenu';
import NavMenuItem from 'components/NavMenuItem';
import ThermometerIcon from 'icons/ThermometerIcon';

const useStyles = makeStyles(() => {
  return {
    menuItemIcon: {
      color: '#000'
    }
  };
});

interface Props {
  recipeId: string;
  className?: string;
}

const ViewRecipeHistoryNavMenu = ({ recipeId, className = '' }: Props) => {
  const classes = useStyles();

  return (
    <NavMenu>
      <NavMenuItem>
        <RouterLink to="/search">
          <SearchIcon className={classes.menuItemIcon} />
        </RouterLink>
      </NavMenuItem>
      <NavMenuItem>
        <RouterLink to={`/recipe/${recipeId}`}>
          <ReceiptIcon className={classes.menuItemIcon} />
        </RouterLink>
      </NavMenuItem>
      <NavMenuItem>
        <RouterLink to="/meat-temperatures">
          <ThermometerIcon className={classes.menuItemIcon} />
        </RouterLink>
      </NavMenuItem>
      <NavMenuItem>
        <RouterLink to="/recents">
          <HistoryIcon className={classes.menuItemIcon} />
        </RouterLink>
      </NavMenuItem>
    </NavMenu>
  );
};

export default ViewRecipeHistoryNavMenu;
