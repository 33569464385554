import React from 'react';
import { useMutation } from '@apollo/client';
import { UPLOAD_MULTI } from 'queries';
import { useSnackbar } from 'notistack';

import { UploadType } from 'types/uploads';
import { ImageType } from 'types/recipes';

type UploadResponse = {
  uploadMulti: UploadType[];
};

type UploadVariables = {
  files: FileList;
};

type Props = {
  className?: string;
  onCompleted: (images: ImageType[]) => void;
};

interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

const formatUploads = (uploads: UploadType[]) => {
  return uploads.map(upload => ({
    uploadId: upload._id,
    filename: upload.filename,
    url: upload.url,
    thumbUrl: upload.thumbUrl,
    miniThumbUrl: upload.miniThumbUrl
  }));
};

const UploadFileList = ({ className, onCompleted }: Props) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [uploadMulti] = useMutation<UploadResponse, UploadVariables>(
    UPLOAD_MULTI,
    {
      onCompleted: ({ uploadMulti }) => {
        if (onCompleted) {
          // Format the upload result into what will be used
          const formattedResults = formatUploads(uploadMulti);

          closeSnackbar(); // Close any snackbars for upload
          enqueueSnackbar(
            formattedResults.length === 1
              ? `Uploaded image successfully.`
              : `Uploaded ${formattedResults.length} images successfully.`,
            {
              variant: 'success'
            }
          );
          onCompleted(formattedResults);
        }
      },
      onError: () => {
        enqueueSnackbar('Unable to upload image(s).', { variant: 'error' });
      }
    }
  );

  const handleChange = ({
    target: { validity, files }
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (!validity.valid || !files) {
      return;
    }

    enqueueSnackbar(
      files.length === 1
        ? `Uploading image...`
        : `Uploading ${files.length} images...`,
      {
        persist: true
      }
    );

    uploadMulti({
      variables: {
        files
      }
    });
  };

  return (
    <input
      className={className || ''}
      id="input-upload"
      type="file"
      multiple
      required
      onChange={handleChange}
    />
  );
};

export default UploadFileList;
