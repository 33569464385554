import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const SaveButton = withStyles({
  root: {
    color: '#76888C',
    fontSize: '20px'
  }
})(Button);

export default SaveButton;
