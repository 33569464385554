import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import { appTitle } from 'constants/names';
import SmallLogo from 'icons/SmallLogo';

const useStyles = makeStyles(() => ({
  grow: {
    flexGrow: 1
  },
  toolbar: {
    justifyContent: 'flex-end'
  }
}));

function AppBarDefault() {
  const classes = useStyles();
  return (
    <AppBar position="sticky" color="default" elevation={1}>
      <Toolbar className={classes.toolbar}>
        <IconButton color="inherit" component={RouterLink} to="/">
          <SmallLogo />
        </IconButton>
        <Typography
          className={classes.grow}
          color="inherit"
          variant="h6"
          noWrap
        >
          {appTitle}
        </Typography>
      </Toolbar>
    </AppBar>
  );
}

export default AppBarDefault;
