import React from 'react';
import ReactMarkdown from 'react-markdown';

type Props = {
  text: string;
};

const MarkdownText = ({ text }: Props) => {
  return <ReactMarkdown source={text} linkTarget="_blank" />;
};

export default MarkdownText;
