import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { useQuery } from "@apollo/client";

import Typography from "@material-ui/core/Typography";
import MuiLink from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import ExpansionPanel from "components/ExpansionPanel";
import ExpansionPanelSummary from "components/ExpansionPanelSummary";
import ExpansionPanelDetails from "components/ExpansionPanelDetails";
import ImageCarousel from "components/ImageCarousel";
import Page from "components/Page";
import Content from "components/Content";
import Tag from "components/Tag";
import ScrollToTopOnMount from "components/ScrollToTopOnMount";

import { RecipeType } from "types/recipes";
import { GET_RECIPE } from "queries";

import ViewRecipeAppBar from "./ViewRecipeAppBar";
import ViewRecipeNavMenu from "./ViewRecipeNavMenu";
import ViewRecipeTabs from "./ViewRecipeTabs";
import ViewRecipeStats from "./ViewRecipeStats";
import MarkdownText from "components/MarkdownText";

type TParams = { id: string };

const useStyles = makeStyles(({ palette, typography }) => ({
  grower: {
    flexGrow: 1,
  },
  panel: {
    boxShadow: "none",
    marginTop: 0,
  },
  header: {
    color: palette.text.secondary,
    fontSize: typography.pxToRem(15),
    fontWeight: typography.fontWeightMedium,
    textTransform: "uppercase",
  },
  tag: {
    "&:not(:last-child)": {
      marginRight: 5,
    },
    marginBottom: 5,
  },
  tags: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: 8,
  },
  topSection: {
    display: "flex",
    alignItems: "flex-start",
    marginTop: 8,
    marginBottom: 0,
  },
}));

type Response = {
  recipe: RecipeType;
};

type Variables = {
  id: string;
};

type RecipeContentProps = {
  recipe: RecipeType;
};

function RecipeContent({ recipe }: RecipeContentProps) {
  const classes = useStyles();
  return (
    <Content>
      <ScrollToTopOnMount />
      <div className={classes.topSection}>
        <ImageCarousel images={recipe.images} />
        <ViewRecipeStats recipe={recipe} />
      </div>
      <ViewRecipeTabs recipe={recipe} />
      {(recipe.notes || recipe.source) && (
        <ExpansionPanel className={classes.panel} defaultExpanded>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.header}>Notes</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div>
              {recipe.notes && <MarkdownText text={recipe.notes} />}
              {recipe.source && (
                <MuiLink href={recipe.source} rel="noreferrer" target="_blank">
                  {recipe.source}
                </MuiLink>
              )}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}
      <div className={classes.tags}>
        {recipe.tags &&
          recipe.tags.map((tag) => (
            <Tag
              className={classes.tag}
              key={tag.name}
              label={tag.name}
              color="primary"
            />
          ))}
      </div>
    </Content>
  );
}

function ViewRecipePage({ history, match }: RouteComponentProps<TParams>) {
  const recipeId = match.params.id;
  const { loading, error, data } = useQuery<Response, Variables>(GET_RECIPE, {
    variables: { id: recipeId },
    fetchPolicy: "cache-and-network",
  });

  let title = "Recipe Name";
  if (error) {
    title = "Unable to load recipe.";
  } else if (loading) {
    title = "Loading...";
  } else if (data && data.recipe && data.recipe.name) {
    title = data.recipe.name;
  }

  return (
    <Page title={title}>
      <ViewRecipeAppBar title={title} recipeId={recipeId} history={history} />
      {!error && data && data.recipe && <RecipeContent recipe={data.recipe} />}
      <ViewRecipeNavMenu recipeId={recipeId} />
    </Page>
  );
}

export default ViewRecipePage;
