import React from 'react';
import Lightbox from 'react-image-lightbox';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import ImageIcon from '@material-ui/icons/Image';
import { ImageType } from 'types/recipes';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';

import 'react-image-lightbox/style.css';

const useStyles = makeStyles(theme => ({
  root: {
    width: '280px',
    height: '210px',
    borderRadius: '4px',
    backgroundColor: '#F4F5F5',
    position: 'relative',

    [theme.breakpoints.down('md')]: {
      width: '240px',
      height: '180px'
    }
  },
  carousel: {
    height: '100%',
    borderRadius: '4px',
    backgroundColor: '#F4F5F5',
    width: '100%',
    whiteSpace: 'nowrap',
    overflowX: 'auto',
    overflowY: 'hidden'
  },
  image: {
    maxWidth: 280,
    maxHeight: 210,
    borderRadius: 4,
    '&:not(:first-child)': {
      marginLeft: '16px'
    },
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      maxWidth: '240px',
      maxHeight: '180px'
    }
  },
  placeholder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  },
  leftArrow: {
    position: 'absolute',
    left: 0,
    top: '50%',
    marginTop: '-20px',
    pointerEvents: 'none'
  },
  rightArrow: {
    position: 'absolute',
    right: 0,
    top: '50%',
    marginTop: '-20px',
    pointerEvents: 'none'
  }
}));

const LargeChevronLeft = withStyles({
  root: {
    width: '40px',
    height: '40px'
  }
})(ChevronLeft);

const LargeChevronRight = withStyles({
  root: {
    width: '40px',
    height: '40px'
  }
})(ChevronRight);

const ImagePlaceholder = withStyles({
  root: {
    width: '40px',
    height: '40px'
  }
})(ImageIcon);

interface Props {
  images: ImageType[];
}

function ImageCarousel({ images }: Props) {
  const [isLightboxOpen, setIsLightboxOpen] = React.useState(false);
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [showLeftArrow, setShowLeftArrow] = React.useState(false);
  const [showRightArrow, setShowRightArrow] = React.useState(images.length > 1);
  const carouselRef = React.useRef<HTMLDivElement>(null);
  const classes = useStyles();

  const handleScrollEvent = React.useCallback(() => {
    if (carouselRef.current) {
      const scrollMax =
        carouselRef.current.scrollWidth - carouselRef.current.clientWidth;

      if (carouselRef.current.scrollLeft > 0) {
        setShowLeftArrow(true);
      } else {
        setShowLeftArrow(false);
      }

      if (carouselRef.current.scrollLeft < scrollMax) {
        setShowRightArrow(true);
      } else {
        setShowRightArrow(false);
      }
    }
  }, []);

  const showPlaceholder = !(images && images.length);

  function renderImages(images: ImageType[]) {
    return (
      <>
        {showLeftArrow && (
          <div className={classes.leftArrow}>
            <LargeChevronLeft color="disabled" />
          </div>
        )}
        {showRightArrow && (
          <div className={classes.rightArrow}>
            <LargeChevronRight color="disabled" />
          </div>
        )}
        <div
          ref={carouselRef}
          className={classes.carousel}
          onScroll={handleScrollEvent}
        >
          {images.map((image, index) => (
            <img
              key={image.uploadId}
              className={classes.image}
              src={image.thumbUrl}
              alt={image.filename}
              onClick={() => {
                setCurrentIndex(index);
                setIsLightboxOpen(true);
              }}
            />
          ))}
        </div>
      </>
    );
  }

  const thumbnailURLs = images.map(image => image.thumbUrl);

  return (
    <div className={classes.root}>
      {showPlaceholder ? (
        <div className={classes.placeholder}>
          <ImagePlaceholder />
        </div>
      ) : (
        renderImages(images)
      )}

      {isLightboxOpen && (
        <Lightbox
          reactModalStyle={{
            overlay: {
              zIndex: 20000
            }
          }}
          mainSrc={thumbnailURLs[currentIndex]}
          nextSrc={thumbnailURLs[(currentIndex + 1) % thumbnailURLs.length]}
          prevSrc={
            thumbnailURLs[
              (currentIndex + thumbnailURLs.length - 1) % thumbnailURLs.length
            ]
          }
          onCloseRequest={() => setIsLightboxOpen(false)}
          onMovePrevRequest={() =>
            setCurrentIndex(
              (currentIndex + thumbnailURLs.length - 1) % thumbnailURLs.length
            )
          }
          onMoveNextRequest={() =>
            setCurrentIndex((currentIndex + 1) % thumbnailURLs.length)
          }
        />
      )}
    </div>
  );
}

export default ImageCarousel;
