import { gql } from '@apollo/client';

const GET_RECIPES = gql`
  query GetRecipes {
    recipes {
      _id
      name
      tags {
        name
      }
      warning
    }
  }
`;

const GET_RECIPE = gql`
  query GetRecipe($id: String!) {
    recipe(id: $id) {
      _id
      name
      tags {
        name
      }
      images {
        uploadId
        filename
        url
        thumbUrl
        miniThumbUrl
      }
      servingSize
      prepTime
      cookTime
      ingredientLists {
        title
        ingredients {
          name
          quantity
          type
        }
      }
      directionLists {
        title
        directions {
          text
        }
      }
      creationEvents {
        date
      }
      warning
      notes
      source
    }
  }
`;

const ADD_RECIPE = gql`
  mutation AddRecipe($input: RecipeInput!) {
    addRecipe(input: $input) {
      _id
    }
  }
`;

const EDIT_RECIPE = gql`
  mutation EditRecipe($id: String!, $input: RecipeInput!) {
    editRecipe(id: $id, input: $input) {
      _id
      name
      tags {
        name
      }
      images {
        uploadId
      }
      servingSize
      prepTime
      cookTime
      ingredientLists {
        title
        ingredients {
          name
          quantity
          type
        }
      }
      directionLists {
        title
        directions {
          text
        }
      }
      warning
      notes
      source
    }
  }
`;

const DELETE_RECIPE = gql`
  mutation DeleteRecipe($id: String!) {
    deleteRecipe(id: $id) {
      _id
    }
  }
`;

const SEARCH_RECIPES = gql`
  query searchRecipes($query: String!, $ingredients: Boolean) {
    searchRecipes(query: $query, ingredients: $ingredients) {
      _id
      name
      warning
    }
  }
`;

const SEARCH_TAGS = gql`
  query SearchTags($query: String!) {
    searchTags(query: $query) {
      name
    }
  }
`;

const GET_CREATION_EVENTS = gql`
  query GetRecipe($id: String!) {
    recipe(id: $id) {
      _id
      name
      creationEvents {
        date
      }
    }
  }
`;

const GET_RECENT_RECIPES = gql`
  query GetRecentRecipes {
    recentRecipes {
      _id
      name
      images {
        miniThumbUrl
      }
      creationEvents {
        date
      }
    }
  }
`;

const ADD_CREATION_EVENT = gql`
  mutation AddCreationEvent($id: String!) {
    addCreationEvent(id: $id) {
      _id
      name
      creationEvents {
        date
      }
    }
  }
`;

const DELETE_CREATION_EVENT = gql`
  mutation DeleteCreationEvent($id: String!, $date: DateTime!) {
    deleteCreationEvent(id: $id, date: $date) {
      _id
      name
      creationEvents {
        date
      }
    }
  }
`;

const GET_UPLOADS = gql`
  query GetUploads {
    uploads {
      _id
      filename
      url
    }
  }
`;

const UPLOAD_MULTI = gql`
  mutation UploadMulti($files: [Upload!]!) {
    uploadMulti(files: $files) {
      _id
      filename
      url
      thumbUrl
      miniThumbUrl
    }
  }
`;

export {
  GET_RECIPES,
  GET_RECIPE,
  GET_CREATION_EVENTS,
  GET_RECENT_RECIPES,
  ADD_RECIPE,
  EDIT_RECIPE,
  DELETE_RECIPE,
  ADD_CREATION_EVENT,
  DELETE_CREATION_EVENT,
  SEARCH_RECIPES,
  SEARCH_TAGS,
  GET_UPLOADS,
  UPLOAD_MULTI
};
