import React from 'react';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';

export default createSvgIcon(
  <React.Fragment>
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g transform="translate(-206.000000, -370.000000)" stroke="#76888C">
        <g transform="translate(207.000000, 370.000000)">
          <g>
            <path d="M17.5155,4.523 C17.6618932,4.66943744 17.6618932,4.90681256 17.5155,5.05325 L9.56025,13.00775 C9.41381256,13.1541432 9.17643744,13.1541432 9.03,13.00775 L5.055,9.03275 L13.275,0.8105 C13.4214374,0.664106773 13.6588126,0.664106773 13.80525,0.8105 L17.5155,4.523 Z" />
            <path d="M8.235,12.21275 L16.71975,3.72725" />
            <path d="M6.64425,10.625 L2.29425,14.975 C1.85352677,15.4140664 1.14031638,15.4127232 0.70125,14.972 C0.262183624,14.5312768 0.26352677,13.8180664 0.70425,13.379 L5.05425,9.029" />
            <path d="M13.4055,3.06425 C13.4590784,3.01050377 13.5397714,2.9943545 13.6099074,3.02334153 C13.6800433,3.05232856 13.7257897,3.12073495 13.7257897,3.196625 C13.7257897,3.27251505 13.6800433,3.34092144 13.6099074,3.36990847 C13.5397714,3.3988955 13.4590784,3.38274623 13.4055,3.329 C13.3702797,3.29395167 13.3504786,3.24631259 13.3504786,3.196625 C13.3504786,3.14693741 13.3702797,3.09929833 13.4055,3.06425" />
          </g>
        </g>
      </g>
    </g>
  </React.Fragment>,
  'TimePrepIcon'
);
