import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { withStyles } from '@material-ui/core/styles';
import HighlightOffTwoToneIcon from '@material-ui/icons/HighlightOffTwoTone';
import { ImageType } from 'types/recipes';

type Props = {
  classes: {
    root: string;
    deleteButton: string;
    deleteIcon: string;
    thumbnail: string;
  };
  className?: string;
  image: ImageType;
  index: number;
  onDelete: () => void;
};

const ImageThumbnailEdit = ({
  className,
  classes,
  image,
  index,
  onDelete
}: Props) => {
  return (
    <Draggable draggableId={`thumbnail-draggable-${index}`} index={index}>
      {provided => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={`${className} ${classes.root}`}
        >
          <button
            type="button"
            className={classes.deleteButton}
            onClick={onDelete}
          >
            <HighlightOffTwoToneIcon className={classes.deleteIcon} />
          </button>
          <img
            className={classes.thumbnail}
            src={image.miniThumbUrl}
            alt={image.filename}
          />
        </div>
      )}
    </Draggable>
  );
};

export default withStyles({
  root: {
    position: 'relative',
    width: '80px',
    height: '80px',
    borderRadius: '4px',
    backgroundColor: '#F4F5F5',
    overflow: 'hidden'
  },
  deleteButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 0,
    margin: 0,
    outline: 0,
    border: 0,
    backgroundColor: 'transparent',
    cursor: 'pointer'
  },
  deleteIcon: {
    color: '#fff',
    '& path:nth-child(1)': {
      opacity: 1,
      fill: '#000'
    }
  },
  thumbnail: {
    maxWidth: 80,
    maxHeight: 80
  }
})(ImageThumbnailEdit);
