import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { makeStyles } from '@material-ui/core/styles';

import noSleep from 'utils/noSleep';

import Page from 'components/Page';
import Content from 'components/Content';
import AppBarDefault from 'components/AppBarDefault';
import NavMenuDefault from 'components/NavMenuDefault';
import { RecipeType } from 'types/recipes';
import { GET_RECENT_RECIPES } from 'queries';
import ImageThumbnail from 'components/ImageThumbnail';
import ImageThumbnailPlaceholder from 'components/ImageThumbnailPlaceholder';

type TParams = { id: string };

const useStyles = makeStyles(({ typography }) => ({
  panel: {
    boxShadow: 'none',
    marginTop: 0
  },
  header: {
    color: 'inherit',
    fontSize: typography.pxToRem(15),
    fontWeight: typography.fontWeightMedium,
    textTransform: 'uppercase'
  },
  list: {
    flexGrow: 1,
    marginTop: 8
  },
  listItem: {
    alignItems: 'start',
    paddingLeft: 0,
    '&:nth-child(odd)': {
      backgroundColor: '#FAFAFA'
    }
  },
  thumbnail: {
    marginRight: 16
  }
}));

type Response = {
  recentRecipes: RecipeType[];
};

type Variables = {};

const title = 'Recently Made';

type RecentsContentProps = {
  recipes: RecipeType[];
};

function RecentsContent({ recipes }: RecentsContentProps) {
  const classes = useStyles();

  // Get tag names and sort alphabetically
  return (
    <Content>
      <Typography variant="h1">{title}</Typography>
      <List className={classes.list} dense disablePadding>
        {recipes &&
          recipes.length > 0 &&
          recipes.map(recipe => {
            const creationEvents = recipe.creationEvents;

            // Recipes must have a creation event
            if (!creationEvents || creationEvents.length === 0) {
              return null;
            }

            const latestEvent = creationEvents.reduce(
              (prevEvent, creationEvent) => {
                const prevDate = new Date(prevEvent.date);
                const date = new Date(creationEvent.date);

                return prevDate.getTime() > date.getTime()
                  ? prevEvent
                  : creationEvent;
              }
            );
            const latestDate = new Date(latestEvent.date).toLocaleDateString();

            return (
              <ListItem
                className={classes.listItem}
                button
                component={RouterLink}
                to={{
                  pathname: `/recipe/${recipe._id}`,
                  state: {
                    useHistoryBack: true
                  }
                }}
                onClick={() => {
                  noSleep.enable();
                }}
                key={recipe._id}
              >
                <ListItemAvatar className={classes.thumbnail}>
                  {recipe.images && recipe.images.length > 0 ? (
                    <ImageThumbnail image={recipe.images[0]} />
                  ) : (
                    <ImageThumbnailPlaceholder />
                  )}
                </ListItemAvatar>
                <ListItemText primary={recipe.name} secondary={latestDate} />
              </ListItem>
            );
          })}
      </List>
    </Content>
  );
}

function RecentsPage() {
  const { loading, error, data } = useQuery<Response, Variables>(
    GET_RECENT_RECIPES,
    {
      fetchPolicy: 'cache-and-network'
    }
  );

  return (
    <Page title={title}>
      <AppBarDefault />
      {error && `Error! ${error.message}`}
      {loading && (!data || !data.recentRecipes) && `Loading...`}
      {data && data.recentRecipes && (
        <RecentsContent recipes={data.recentRecipes} />
      )}
      <NavMenuDefault />
    </Page>
  );
}

export default RecentsPage;
