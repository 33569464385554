import React from 'react';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';

export default createSvgIcon(
  <React.Fragment>
    <g clipPath="url(#thermometer-icon)">
      <path
        d="M13.5 15.418V3.75C13.5 2.95435 13.1839 2.19129 12.6213 1.62868C12.0587 1.06607 11.2957 0.75 10.5 0.75C9.70435 0.75 8.94129 1.06607 8.37868 1.62868C7.81607 2.19129 7.5 2.95435 7.5 3.75V15.418C6.82071 16.0256 6.34191 16.8251 6.12696 17.7107C5.91201 18.5964 5.97103 19.5264 6.29623 20.3778C6.62142 21.2292 7.19745 21.9617 7.9481 22.4786C8.69875 22.9954 9.58864 23.2721 10.5 23.2721C11.4114 23.2721 12.3012 22.9954 13.0519 22.4786C13.8025 21.9617 14.3786 21.2292 14.7038 20.3778C15.029 19.5264 15.088 18.5964 14.873 17.7107C14.6581 16.8251 14.1793 16.0256 13.5 15.418V15.418Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M10.5 20.25C11.3284 20.25 12 19.5784 12 18.75C12 17.9216 11.3284 17.25 10.5 17.25C9.67157 17.25 9 17.9216 9 18.75C9 19.5784 9.67157 20.25 10.5 20.25Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M10.5 8.25V17.25"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5 8.25H18"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5 12.75H18"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="thermometer-icon">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </React.Fragment>,
  'ThermometerIcon'
);
