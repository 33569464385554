import React, { useMemo } from 'react';

import Checklist from 'components/Checklist';
import { DirectionType, DirectionListType } from 'types/recipes';
import { Typography } from '@material-ui/core';
import formatMeasurements from 'utils/formatMeasurements';

interface Props {
  className?: string;
  storeKey?: string;
  showTitle: boolean;
  directionList: DirectionListType;
}

const formatDirectionItems = (directions: DirectionType[]) => {
  return directions.map(direction => {
    return {
      text: formatMeasurements(direction.text)
    };
  });
};

function DirectionList({
  className,
  showTitle,
  directionList,
  storeKey
}: Props) {
  const items = useMemo(() => formatDirectionItems(directionList.directions), [
    directionList
  ]);

  return (
    <div className={className}>
      {showTitle && <Typography variant="h3">{directionList.title}</Typography>}
      <Checklist
        isNumbered
        items={items}
        storeKey={storeKey ? `${storeKey}-directions` : null}
      />
    </div>
  );
}

export default DirectionList;
