import React from 'react';
import MuiTab, { TabProps } from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';

const Tab = (props: TabProps) => {
  return <MuiTab {...props} />;
};

export default withStyles({
  root: {
    flexGrow: 1,
    maxWidth: 'none'
    // backgroundColor: "#efefef",
  }
})(Tab);
