import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { MeatTemperatureType } from 'types/meatTemperatures';

const useStyles = makeStyles(() => ({
  temperature: {
    fontWeight: 500,
    fontSize: 18,
    lineHeight: '21px'
  },
  name: {
    fontSize: 14,
    lineHeight: '16px'
  }
}));

interface Props {
  className?: string;
  meatTemperature: MeatTemperatureType;
}

const MeatTemperature = ({ className = '', meatTemperature }: Props) => {
  const classes = useStyles();

  return (
    <div className={className}>
      <div className={classes.temperature}>{meatTemperature.temperature}</div>
      <div className={classes.name}>{meatTemperature.name}</div>
    </div>
  );
};

export default MeatTemperature;
