import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => {
  return {
    hamburger: {
      font: 'inherit',
      display: 'inline-block',
      overflow: 'visible',
      margin: 0,
      padding: 16,
      cursor: 'pointer',
      transitionTimingFunction: 'linear',
      transitionDuration: '.15s',
      transitionProperty: 'opacity,filter',
      textTransform: 'none',
      color: 'inherit',
      border: 0,
      backgroundColor: 'transparent',

      '&:hover': {
        opacity: 0.7
      }
    },
    hamburgerBox: {
      position: 'relative',
      display: 'inline-block',
      width: 24,
      height: 24
    },
    hamburgerInner: {
      left: 3,
      '&, &:before, &:after': {
        position: 'absolute',
        width: 18,
        height: 3,
        transitionTimingFunction: 'cubic-bezier(.55,.055,.675,.19)',
        transitionDuration: '75ms',
        transitionProperty: 'transform',
        backgroundColor: '#000'
      },
      '&:before': {
        transition: 'top 75ms ease .12s,opacity 75ms ease',
        top: -6,
        display: 'block',
        content: '""'
      },
      '&:after': {
        transition:
          'bottom 75ms ease .12s,transform 75ms cubic-bezier(.55,.055,.675,.19)',
        bottom: -6,
        display: 'block',
        content: '""'
      }
    },
    hamburgerInnerActive: {
      transitionDelay: '.12s',
      transitionTimingFunction: 'cubic-bezier(.215,.61,.355,1)',
      transform: 'rotate(45deg)',
      '&:before': {
        top: 0,
        transition: 'top 75ms ease,opacity 75ms ease .12s',
        opacity: 0
      },
      '&:after': {
        bottom: 0,
        transition:
          'bottom 75ms ease,transform 75ms cubic-bezier(.215,.61,.355,1) .12s',
        transform: 'rotate(-90deg)'
      }
    }
  };
});

interface Props {
  className?: string;
  isOpen: boolean;
}

const NavMenuHamburgerIcon = ({ className = '', isOpen }: Props) => {
  const classes = useStyles();

  return (
    <div className={`${className} ${classes.hamburger}`}>
      <div className={classes.hamburgerBox}>
        <div
          className={`${classes.hamburgerInner} ${
            isOpen ? classes.hamburgerInnerActive : ''
          }`}
        />
      </div>
    </div>
  );
};

export default NavMenuHamburgerIcon;
