import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ palette, typography }) => ({
  root: {
    paddingTop: 8
  }
}));

type TabPanelProps = {
  tabId: number;
  selected: boolean;
  children: ReactElement | ReactElement[];
};

function TabPanel({ tabId, selected, ...rest }: TabPanelProps) {
  const classes = useStyles();
  return (
    <div
      className={classes.root}
      role="tabpanel"
      hidden={!selected}
      id={`tabpanel-${tabId}`}
      aria-labelledby={`tab-${tabId}`}
      {...rest}
    />
  );
}

export default TabPanel;
