import React from 'react';
import Logo from 'icons/Logo';
import { makeStyles } from '@material-ui/core/styles';

import './LoadingPlaceholder.css';

const useStyles = makeStyles(() => {
  return {
    content: {
      minHeight: '90vh',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    logo: {
      marginTop: -64,
      width: 200,
      height: 200
    }
  };
});

const LoadingPlaceholder = () => {
  const classes = useStyles();
  return (
    <div className={classes.content}>
      <Logo className={`loading-animation ${classes.logo}`} />
    </div>
  );
};

export default LoadingPlaceholder;
