import React from 'react';
import { RouteComponentProps, Prompt } from 'react-router-dom';
import { History } from 'history';
import { Formik, Form, FormikActions } from 'formik';
import { useSnackbar } from 'notistack';
import { useQuery, useMutation } from '@apollo/client';

// @ts-ignore
import omitDeep from 'omit-deep';
import cloneDeep from 'lodash/cloneDeep';

import Page from 'components/Page';
import Content from 'components/Content';

import { RecipeType, RecipeInputType } from 'types/recipes';
import { GET_RECIPE, EDIT_RECIPE, DELETE_RECIPE } from 'queries';

import EditRecipeAppBar from './EditRecipeAppBar';
import RecipeEditForm from 'components/RecipeEditForm';
import formatRecipeInput from 'utils/formatRecipeInput';
import ScrollToTopOnMount from 'components/ScrollToTopOnMount';

type TParams = { id: string };

type GetResponse = {
  recipe: RecipeType;
};

type GetVariables = {
  id: string;
};

type EditResponse = {
  editRecipe: RecipeType;
};

type EditVariables = {
  id: string;
  input: RecipeInputType;
};

type EditRecipeContentProps = {
  id: string;
  recipe: RecipeType;
  history: History;
};

type DeleteResponse = {
  deleteRecipe: RecipeType;
};

type DeleteVariables = {
  id: string;
};

function EditRecipeContent({ id, recipe, history }: EditRecipeContentProps) {
  const { enqueueSnackbar } = useSnackbar();

  const { _id, ...rest } = recipe;
  const clonedRecipeValues = cloneDeep(rest); // Clone because __typename is returned as readonly
  const originalRecipeValues = omitDeep(clonedRecipeValues, [
    '__typename',
    'creationEvents'
  ]);

  const title = `Edit ${recipe.name}`;

  const [editRecipe] = useMutation<EditResponse, EditVariables>(EDIT_RECIPE, {
    onCompleted: ({ editRecipe }) => {
      const { _id } = editRecipe;
      enqueueSnackbar('Recipe modified successfully.', {
        variant: 'success'
      });
      history.push(`/recipe/${_id}`);
    },
    onError: () => {
      enqueueSnackbar('Unable to modify recipe.', { variant: 'error' });
    }
  });

  const [deleteRecipe] = useMutation<DeleteResponse, DeleteVariables>(
    DELETE_RECIPE,
    {
      onCompleted: () => {
        enqueueSnackbar('Recipe deleted successfully.', {
          variant: 'success'
        });
        history.push(`/`);
      },
      onError: () => {
        enqueueSnackbar('Unable to delete recipe.', { variant: 'error' });
      }
    }
  );

  return (
    <Formik
      initialValues={{ ...originalRecipeValues }}
      onSubmit={(
        values: RecipeType,
        { setSubmitting }: FormikActions<RecipeType>
      ) => {
        setTimeout(() => {
          const formattedValues = formatRecipeInput(values);
          editRecipe({
            variables: { id: id, input: formattedValues }
          });
          setSubmitting(false);
        }, 500);
      }}
      render={formikProps => (
        <Form noValidate autoComplete="off">
          <Prompt
            when={formikProps.dirty && formikProps.submitCount === 0}
            message="Are you sure you want to leave? You have with unsaved changes."
          />
          <EditRecipeAppBar
            id={id}
            title={title}
            onDelete={() => {
              formikProps.setSubmitting(true);
              deleteRecipe({
                variables: { id: id }
              });
            }}
            disableSubmit={formikProps.isSubmitting}
          />
          <Content>
            <RecipeEditForm {...formikProps} />
          </Content>
        </Form>
      )}
    />
  );
}

function EditRecipePage({ match, history }: RouteComponentProps<TParams>) {
  const recipeId = match.params.id;
  const { loading, error, data } = useQuery<GetResponse, GetVariables>(
    GET_RECIPE,
    {
      variables: { id: recipeId }
    }
  );

  let title = 'Edit Recipe';
  if (loading) {
    title = 'Loading...';
  }

  return (
    <Page title={title}>
      <ScrollToTopOnMount />
      {loading && <EditRecipeAppBar title={'Loading'} disableSubmit={true} />}
      {error && `Could not load recipe information`}
      {!loading && !error && data && data.recipe && (
        <EditRecipeContent
          id={recipeId}
          recipe={data.recipe}
          history={history}
        />
      )}
    </Page>
  );
}

export default EditRecipePage;
