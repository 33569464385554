import { withStyles } from '@material-ui/core/styles';
import Logo from './Logo';

const SmallLogo = withStyles({
  root: {
    fill: 'black',
    width: '32px',
    height: '32px'
  }
})(Logo);

export default SmallLogo;
