import React, { useReducer, useMemo, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';

import ListItem from 'components/ListItem';
import ListItemNumber from 'components/ListItemNumber';
import Checkbox from 'components/Checkbox';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%'
  },
  button: {
    marginTop: '8px'
  },
  list: {
    width: '100%'
  },
  listItemText: {
    lineHeight: '18px',
    padding: '0 8px',
    margin: '0'
  },
  listItemTextChecked: {
    color: '#B1B6B8',
    fontStyle: 'italic',
    textDecoration: 'line-through'
  }
}));

interface Item {
  text: string;
}

interface Props {
  items: Item[];
  isNumbered?: boolean;
  storeKey: string | null | undefined;
}

interface State {
  checked: number[];
}

type Action =
  | { type: 'check' | 'uncheck'; payload: { index: number } }
  | { type: 'reset' };

function getInitialState(storeKey: string | null | undefined) {
  let checked: number[] = [];
  if (storeKey) {
    const localStoreValue = window.localStorage.getItem(storeKey);
    if (localStoreValue != null) {
      const parsedValue = JSON.parse(localStoreValue);
      if (Array.isArray(parsedValue)) {
        checked = parsedValue.map(value => parseInt(value, 10));
      }
    }
  }
  return {
    checked
  };
}

function saveCheckedItems(
  storeKey: string | null | undefined,
  checked: number[]
) {
  if (storeKey) {
    window.localStorage.setItem(storeKey, JSON.stringify(checked));
  }
}

function reducer(state: State, action: Action) {
  switch (action.type) {
    case 'check': {
      const isCheckedAlready =
        state.checked.indexOf(action.payload.index) !== -1;
      return {
        ...state,
        checked: isCheckedAlready
          ? state.checked
          : [...state.checked, action.payload.index]
      };
    }
    case 'uncheck': {
      const index = state.checked.indexOf(action.payload.index);
      const newChecked = [...state.checked];
      if (index !== -1) {
        newChecked.splice(index, 1);
      }
      return {
        ...state,
        checked: newChecked
      };
    }
    case 'reset': {
      return {
        ...state,
        checked: []
      };
    }
    default:
      throw new Error('invalid action');
  }
}

function Checklist({ isNumbered, items, storeKey }: Props) {
  const classes = useStyles();
  const initialState = useMemo(() => getInitialState(storeKey), [storeKey]);
  const [state, dispatch] = useReducer(reducer, initialState);

  // the localStorage value of word
  useEffect(() => saveCheckedItems(storeKey, state.checked), [
    storeKey,
    state.checked
  ]);

  return (
    <div className={classes.root}>
      <List className={classes.list} dense disablePadding>
        {items.map((option, index) => {
          const isChecked = state.checked.indexOf(index) !== -1;
          return (
            <ListItem
              key={index}
              role={undefined}
              dense
              disableGutters
              button
              onClick={() => {
                dispatch({
                  type: isChecked ? 'uncheck' : 'check',
                  payload: { index }
                });
              }}
            >
              <Checkbox checked={isChecked} tabIndex={-1} disableRipple />
              {isNumbered && <ListItemNumber number={index + 1} />}
              <ListItemText
                className={`${classes.listItemText} ${
                  isChecked ? classes.listItemTextChecked : ''
                }`}
                disableTypography
                primary={option.text}
              />
            </ListItem>
          );
        })}
      </List>
      <Button
        className={classes.button}
        variant="outlined"
        color="primary"
        onClick={() => {
          dispatch({ type: 'reset' });
        }}
      >
        Reset
      </Button>
    </div>
  );
}

export default Checklist;
