import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import IconButton from '@material-ui/core/IconButton';

import HourglassRoundIcon from 'icons/HourglassRoundIcon';

const useStyles = makeStyles(() => {
  return {
    button: {
      padding: 0,
      marginLeft: 24 // Give breathing room to click button
    },
    tooltip: {
      fontSize: 12,
      margin: 0,
      marginTop: 4
    }
  };
});

interface Props {
  className?: string;
  warning: string;
}

const WarningNotificationTooltipButton = ({
  className = '',
  warning
}: Props) => {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const handleTooltipClose = () => {
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Tooltip
          classes={{
            tooltip: classes.tooltip
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={warning}
        >
          <IconButton
            onClick={() => {
              setOpen(!open);
            }}
            className={classes.button}
          >
            <HourglassRoundIcon />
          </IconButton>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
};

export default WarningNotificationTooltipButton;
