import React from 'react';
import { Field, FieldArray, FormikProps } from 'formik';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import TextField from 'components/TextField';
import ImageThumbnailListEdit from 'components/ImageThumbnailListEdit';
import IngredientListEdit from 'components/IngredientListEdit';
import DirectionListEdit from 'components/DirectionListEdit';
import TagListEdit from 'components/TagListEdit';
import SmallAddCircleIcon from 'icons/SmallAddCircleIcon';

import { RecipeType } from 'types/recipes';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex'
  },
  addButton: {
    paddingLeft: 0,
    marginBottom: 8
  },
  cookTimes: {
    display: 'flex'
  },
  timeTextField: {
    flex: 1,
    '&:not(:first-child)': {
      marginLeft: '16px'
    }
  },
  sectionHeader: {
    borderBottom: '1px solid #DEE0E1',
    paddingBottom: 8
  },
  imageHeader: {
    marginTop: 8
  }
}));

interface Props {
  autoFocusName?: boolean;
}

function CreateRecipePage({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  autoFocusName
}: Props & FormikProps<RecipeType>) {
  const classes = useStyles();

  return (
    <div>
      <Field
        component={TextField}
        inputProps={{ name: 'name' }}
        type="name"
        label="Recipe Name"
        placeholder="Recipe Name"
        margin="normal"
        variant="outlined"
        value={values.name}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={errors.name && touched.name && errors.name}
        fullWidth
        autoFocus={autoFocusName}
      />
      <Typography variant="h2">Tags</Typography>
      <div>
        <Field name="tags" tags={values.tags} component={TagListEdit} />
      </div>
      <Typography variant="h2" classes={{ root: classes.imageHeader }}>
        Images
      </Typography>
      <ImageThumbnailListEdit images={values.images} />
      <div className={classes.cookTimes}>
        <Field
          className={classes.timeTextField}
          inputProps={{ name: 'servingSize' }}
          component={TextField}
          label="Servings"
          placeholder="Servings"
          type="number"
          margin="normal"
          variant="outlined"
          value={values.servingSize || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={
            errors.servingSize && touched.servingSize && errors.servingSize
          }
        />
        <Field
          className={classes.timeTextField}
          inputProps={{ name: 'prepTime' }}
          component={TextField}
          label="Prep Time"
          placeholder="Prep Time"
          type="number"
          margin="normal"
          variant="outlined"
          helperText="In minutes"
          value={values.prepTime || ''}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <Field
          className={classes.timeTextField}
          inputProps={{ name: 'cookTime' }}
          component={TextField}
          label="Cook Time"
          placeholder="Cook Time"
          type="number"
          margin="normal"
          variant="outlined"
          helperText="In minutes"
          value={values.cookTime || ''}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
      <Typography variant="h2" className={classes.sectionHeader}>
        Ingredients
      </Typography>
      <FieldArray
        name={`ingredientLists`}
        render={arrayHelpers => {
          return (
            <>
              {values.ingredientLists &&
                values.ingredientLists.length > 0 &&
                values.ingredientLists.map((ingredientList, index) => (
                  <IngredientListEdit
                    fieldPrefix={`ingredientLists.${index}`}
                    ingredientList={ingredientList}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onSectionRemove={() => arrayHelpers.remove(index)}
                    showSectionTitle={values.ingredientLists.length > 1}
                    key={index}
                  />
                ))}
              <Button
                color="primary"
                className={classes.addButton}
                onClick={() =>
                  arrayHelpers.push({
                    title: '',
                    ingredients: [{ name: '', quantity: '', type: '' }]
                  })
                }
              >
                <SmallAddCircleIcon color="primary" />
                Add Section
              </Button>
            </>
          );
        }}
      />
      <Typography variant="h2" className={classes.sectionHeader}>
        Directions
      </Typography>
      <FieldArray
        name={`directionLists`}
        render={arrayHelpers => {
          return (
            <>
              {values.directionLists &&
                values.directionLists.length > 0 &&
                values.directionLists.map((directionList, index) => (
                  <DirectionListEdit
                    directionList={directionList}
                    fieldPrefix={`directionLists.${index}`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onSectionRemove={() => arrayHelpers.remove(index)}
                    showSectionTitle={values.directionLists.length > 1}
                    key={index}
                  />
                ))}
              <Button
                color="primary"
                className={classes.addButton}
                onClick={() =>
                  arrayHelpers.push({
                    title: '',
                    directions: [{ text: '' }]
                  })
                }
              >
                <SmallAddCircleIcon color="primary" />
                Add Section
              </Button>
            </>
          );
        }}
      />
      <Typography variant="h2" className={classes.sectionHeader}>
        Notes
      </Typography>
      <Field
        label="Warning Notification"
        placeholder="Warning Notification"
        component={TextField}
        inputProps={{ name: 'warning' }}
        margin="normal"
        variant="outlined"
        fullWidth
        value={values.warning || ''}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <Field
        label="Source URL"
        placeholder="Source URL"
        component={TextField}
        inputProps={{ name: 'source' }}
        type="url"
        margin="normal"
        variant="outlined"
        fullWidth
        value={values.source}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <Field
        label="Notes"
        placeholder="Notes"
        component={TextField}
        inputProps={{ name: 'notes' }}
        multiline
        rows="8"
        margin="normal"
        variant="outlined"
        fullWidth
        value={values.notes}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </div>
  );
}

export default CreateRecipePage;
