import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import { withStyles } from '@material-ui/core/styles';

const ExpansionPanelDenseSummary = withStyles({
  root: {
    borderBottom: '1px solid #DEE0E1',
    minHeight: 24,
    '&$expanded': {
      borderBottom: '1px solid #059ADB',
      minHeight: 24
    },
    padding: 0
  },
  content: {
    margin: '8px 0',
    color: '#76888C',
    '&$expanded': {
      color: '#059ADB',
      margin: '8px 0'
    }
  },
  expandIcon: {
    transform: 'rotate(270deg)',
    padding: '8px',
    color: '#76888C',
    marginRight: 0,
    '&$expanded': {
      color: '#059ADB',
      transform: 'rotate(180deg)'
    }
  },
  expanded: {}
})(MuiExpansionPanelSummary);

export default ExpansionPanelDenseSummary;
