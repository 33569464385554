import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import NavMenu from 'components/NavMenu';
import NavMenuItem from 'components/NavMenuItem';
import ThermometerIcon from 'icons/ThermometerIcon';

const useStyles = makeStyles(() => {
  return {
    menuItemIcon: {
      color: '#000'
    }
  };
});

interface Props {
  className?: string;
}

const NavMenuDefault = ({ className = '' }: Props) => {
  const classes = useStyles();

  return (
    <NavMenu>
      <NavMenuItem>
        <RouterLink to="/search">
          <SearchIcon className={classes.menuItemIcon} />
        </RouterLink>
      </NavMenuItem>
      <NavMenuItem>
        <RouterLink to="/create">
          <AddIcon className={classes.menuItemIcon} />
        </RouterLink>
      </NavMenuItem>
      <NavMenuItem>
        <RouterLink to="/meat-temperatures">
          <ThermometerIcon className={classes.menuItemIcon} />
        </RouterLink>
      </NavMenuItem>
      <NavMenuItem>
        <RouterLink to="/recents">
          <AccessTimeIcon className={classes.menuItemIcon} />
        </RouterLink>
      </NavMenuItem>
    </NavMenu>
  );
};

export default NavMenuDefault;
