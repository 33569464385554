function strToVulgarFraction(str: string) {
  switch (str) {
    case '1/2':
      return '½';
    case '1/3':
      return '⅓';
    case '2/3':
      return '⅔';
    case '1/4':
      return '¼';
    case '3/4':
      return '¾';
    case '1/5':
      return '⅕';
    case '2/5':
      return '⅖';
    case '3/5':
      return '⅗';
    case '4/5':
      return '⅘';
    case '1/6':
      return '⅙';
    case '5/6':
      return '⅚';
    case '1/7':
      return '⅐';
    case '1/8':
      return '⅛';
    case '3/8':
      return '⅜';
    case '5/8':
      return '⅝';
    case '7/8':
      return '⅞';
    case '1/9':
      return '⅑';
    case '1/10':
      return '⅒';
    default:
      return str.replace('/', '⁄'); // Special fractal slash
  }
}

const formatMeasurements = (str: string) => {
  const formattedString = str.replace(/\b(\d+)\/(\d+)\b/g, strToVulgarFraction);
  return formattedString;
};

export default formatMeasurements;
