import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Checkbox from 'components/Checkbox';

const useStyles = makeStyles(({ typography }) => ({
  root: {
    height: 36,
    display: 'flex',
    alignItems: 'center'
  },
  labelText: {
    display: 'inline-block',
    paddingLeft: 4
  }
}));

type SearchIngredientsToggleProps = {
  checked: boolean;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
};

function SearchIngredientsToggle({
  checked,
  onChange
}: SearchIngredientsToggleProps) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <label>
        <Checkbox
          checked={checked}
          tabIndex={-1}
          disableRipple
          onChange={onChange}
        />
        <div className={classes.labelText}>Search by ingredients</div>
      </label>
    </div>
  );
}

export default SearchIngredientsToggle;
