import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ImageIcon from '@material-ui/icons/Image';

type Props = {
  classes: {
    root: string;
  };
  className?: string;
};

const ImagePlaceholder = withStyles({
  root: {
    width: '40px',
    height: '40px'
  }
})(ImageIcon);

const ImageThumbnailPlaceholder = ({ className, classes }: Props) => {
  return (
    <div className={`${className} ${classes.root}`}>
      <ImagePlaceholder color="action" />
    </div>
  );
};

export default withStyles({
  root: {
    display: 'flex',
    width: '80px',
    height: '80px',
    borderRadius: '4px',
    backgroundColor: '#F4F5F5',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '0 0 auto'
  }
})(ImageThumbnailPlaceholder);
