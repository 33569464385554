import React from 'react';
import Typography from '@material-ui/core/Typography';

import Page from 'components/Page';
import Content from 'components/Content';

import UploadFileList from 'components/UploadFileList';
import { ImageType } from 'types/recipes';

const title = 'TestPage';

function TestPage() {
  return (
    <Page title={title}>
      <Content>
        <Typography variant="h1">{title}</Typography>
        <UploadFileList
          onCompleted={(images: ImageType[]) => {
            console.log(images);
          }}
        />
      </Content>
    </Page>
  );
}

export default TestPage;
