import React from 'react';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';

export default createSvgIcon(
  <React.Fragment>
    <path
      d="M15.75 16.45C15.75 18.107 14.071 18.7 12 18.7C9.929 18.7 8.25 18.107 8.25 16.45C8.31884 15.4782 8.73603 14.5638 9.42493 13.8749C10.1138 13.186 11.0282 12.7689 12 12.7C12.9718 12.7689 13.8862 13.186 14.5751 13.8749C15.264 14.5638 15.6812 15.4782 15.75 16.45V16.45Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M10.5 15.7V16.45"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.5 15.7V16.45"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.615 17.2L18.615 14.2C18.615 14.2 20.865 17.2 18.615 18.7C16.642 20.016 15.615 17.2 15.615 17.2Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M8.38599 17.2L5.38599 14.2C5.38599 14.2 3.13599 17.2 5.38599 18.7C7.35899 20.016 8.38599 17.2 8.38599 17.2Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M7.5 21.7C8.76087 22.7429 10.3655 23.2778 12 23.2C13.6345 23.2778 15.2391 22.7429 16.5 21.7"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.5 23.247C1.5 20.95 0.75 18.215 0.75 14.95C0.777851 12.7952 1.26968 10.6717 2.192 8.724C1.943 7.732 1.011 3.738 1.731 2.256C2.431 0.81 5.667 3.056 6.75 3.7L8.25 2.2L9.75 2.95L12 0.75L14.25 2.95L15.75 2.2L17.25 3.7C18.35 3.042 21.535 0.818 22.233 2.256C22.943 3.719 22.045 7.625 21.783 8.679C22.7203 10.6385 23.2208 12.7781 23.25 14.95C23.25 18.216 22.5 20.95 19.501 23.25"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M7.125 8.95001C7.22446 8.95001 7.31984 8.98952 7.39016 9.05985C7.46049 9.13017 7.5 9.22556 7.5 9.32501"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.75 9.32501C6.75 9.22556 6.78951 9.13017 6.85984 9.05985C6.93016 8.98952 7.02554 8.95001 7.125 8.95001"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.125 9.70001C7.02554 9.70001 6.93016 9.6605 6.85984 9.59018C6.78951 9.51985 6.75 9.42447 6.75 9.32501"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.5 9.32501C7.5 9.42447 7.46049 9.51985 7.39016 9.59018C7.31984 9.6605 7.22446 9.70001 7.125 9.70001"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.875 8.95001C16.9745 8.95001 17.0698 8.98952 17.1402 9.05985C17.2105 9.13017 17.25 9.22556 17.25 9.32501"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.5 9.32501C16.5 9.22556 16.5395 9.13017 16.6098 9.05985C16.6802 8.98952 16.7755 8.95001 16.875 8.95001"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.875 9.70001C16.7755 9.70001 16.6802 9.6605 16.6098 9.59018C16.5395 9.51985 16.5 9.42447 16.5 9.32501"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.25 9.32501C17.25 9.42447 17.2105 9.51985 17.1402 9.59018C17.0698 9.6605 16.9745 9.70001 16.875 9.70001"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </React.Fragment>,
  'PorkIcon'
);
