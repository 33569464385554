import { withStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';

const SmallAddCircleIcon = withStyles({
  root: {
    width: '16px',
    height: '16px',
    marginRight: '4px'
  }
})(AddCircleIcon);

export default SmallAddCircleIcon;
