import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import { withStyles } from '@material-ui/core/styles';

const ExpansionPanel = withStyles({
  root: {
    border: 0,
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    }
  },
  expanded: {
    // Unfortunate hack
    '&.Mui-expanded': {
      margin: 0
    }
  }
})(MuiExpansionPanel);

export default ExpansionPanel;
