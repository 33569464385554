import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { RecipeType } from 'types/recipes';

import Tabs from 'components/Tabs';
import Tab from 'components/Tab';
import TabPanel from 'components/TabPanel';
import DirectionList from 'components/DirectionList';
import IngredientList from 'components/IngredientList';

type ViewRecipeTabs = {
  recipe: RecipeType;
  classes: {
    root: string;
    directionList: string;
    ingredientList: string;
  };
};

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`
  };
}

const ViewRecipeTabs = (props: ViewRecipeTabs) => {
  const { recipe, classes } = props;
  const [tabIndex, setTabIndex] = React.useState(0);

  const handleChange = (event: any, tabIndex: number) => {
    setTabIndex(tabIndex);
  };

  return (
    <div>
      <Tabs value={tabIndex} onChange={handleChange as any}>
        <Tab label="Ingredients" {...a11yProps(0)} />
        <Tab label="Directions" {...a11yProps(1)} />
      </Tabs>
      <TabPanel tabId={0} selected={tabIndex === 0}>
        {recipe.ingredientLists &&
          recipe.ingredientLists.map((ingredientList, index) => (
            <IngredientList
              className={classes.ingredientList}
              showTitle={recipe.ingredientLists.length > 1}
              storeKey={`${recipe._id}-${index}`}
              ingredientList={ingredientList}
              key={`${recipe._id}-${index}`}
            />
          ))}
      </TabPanel>
      <TabPanel tabId={1} selected={tabIndex === 1}>
        {recipe.directionLists &&
          recipe.directionLists.map((directionList, index) => (
            <DirectionList
              className={classes.directionList}
              showTitle={recipe.directionLists.length > 1}
              storeKey={`${recipe._id}-${index}`}
              directionList={directionList}
              key={`${recipe._id}-${index}`}
            />
          ))}
      </TabPanel>
    </div>
  );
};

export default withStyles({
  root: {
    borderRadius: '20px',
    height: '24px'
  },
  directionList: {
    '&:not(:first-child)': {
      marginTop: 20
    }
  },
  ingredientList: {
    '&:not(:first-child)': {
      marginTop: 20
    }
  }
})(ViewRecipeTabs);

// {
//   recipe.ingredientLists && recipe.ingredientLists.length > 0 && (
//     <ExpansionPanel className={classes.panel} defaultExpanded>
//       <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
//         <Typography className={classes.header}>Ingredients</Typography>
//       </ExpansionPanelSummary>
//       <ExpansionPanelDetails>
//         {recipe.ingredientLists &&
//           recipe.ingredientLists.map((ingredientList, index) => (
//             <IngredientList
//               className={classes.ingredientList}
//               showTitle={recipe.ingredientLists.length > 1}
//               storeKey={`${recipe._id}-${index}`}
//               ingredientList={ingredientList}
//               key={`${recipe._id}-${index}`}
//             />
//           ))}
//       </ExpansionPanelDetails>
//     </ExpansionPanel>
//   );
// }
// {
//   recipe.directionLists && recipe.directionLists.length > 0 && (
//     <ExpansionPanel className={classes.panel} defaultExpanded>
//       <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
//         <Typography className={classes.header}>Directions</Typography>
//       </ExpansionPanelSummary>
//       <ExpansionPanelDetails>
//         {recipe.directionLists &&
//           recipe.directionLists.map((directionList, index) => (
//             <DirectionList
//               className={classes.directionList}
//               showTitle={recipe.directionLists.length > 1}
//               storeKey={`${recipe._id}-${index}`}
//               directionList={directionList}
//               key={`${recipe._id}-${index}`}
//             />
//           ))}
//       </ExpansionPanelDetails>
//     </ExpansionPanel>
//   );
// }
