import React from 'react';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1
  }
}));

interface Props {
  children?: React.ReactNode;
  title: string;
}

function Page({ title, children }: Props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Helmet
        title={title}
        meta={[
          {
            name: 'robots',
            content: 'noindex, nofollow'
          }
        ]}
      />
      {children}
    </div>
  );
}

export default Page;
