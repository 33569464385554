import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => {
  return {
    menuItem: {
      position: 'absolute',
      top: '.2em',
      right: '.2em',
      zIndex: -1,
      display: 'block',
      textDecoration: 'none',
      color: 'hsl(0, 0%, 100%)',
      fontSize: '1em',
      width: '3em',
      height: '3em',
      borderRadius: '50%',
      textAlign: 'center',
      lineHeight: 3,
      backgroundColor: 'hsla(0,0%,0%,.1)',
      transition: 'transform .3s ease, background .2s ease',
      padding: 8
    },
    menuItemVisible: {
      transitionTimingFunction: 'cubic-bezier(0.175, 0.885, 0.32, 1.275)',
      '&:nth-child(1)': {
        transform: 'translate3d(.6em,-7.2em,0)'
      },
      '&:nth-child(2)': {
        transform: 'translate3d(-3.5em,-6.2em,0)'
      },
      '&:nth-child(3)': {
        transform: 'translate3d(-6.3em,-3em,0)'
      },
      '&:nth-child(4)': {
        transform: 'translate3d(-7em,1em,0)'
      }
    }
  };
});

interface Props {
  children: ReactElement;
  className?: string;
  onClick?: () => void;
  /* @ignore */
  isVisible?: boolean;
}

const NavMenuItem = ({
  children,
  className = '',
  onClick = () => {},
  isVisible = false
}: Props) => {
  const classes = useStyles();

  return (
    <div
      onClick={onClick}
      className={`${classes.menuItem} ${
        isVisible ? classes.menuItemVisible : ''
      }`}
    >
      {children}
    </div>
  );
};

export default NavMenuItem;
