import React, { ReactElement, useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import NavMenuHamburgerIcon from 'components/NavMenuHamburgerIcon';

const useStyles = makeStyles(({ palette }) => {
  return {
    navMenuContainer: {
      position: 'fixed',
      bottom: '1em',
      right: '1em',

      '&:after': {
        display: 'block',
        content: '""',
        width: '3.5em',
        height: '3.5em',
        borderRadius: '50%',
        position: 'absolute',
        top: 0,
        right: 0,
        zIndex: -2,
        backgroundColor: palette.secondary.main,
        transition: 'all .3s ease'
      }
    },
    navMenuContainerOpen: {
      '&:after': {
        transform: 'scale3d(5.5, 5.5, 1)',
        transitionTimingFunction: 'cubic-bezier(.68, 1.55, .265, 1)'
      }
    },
    navMenuButton: {
      display: 'block',
      width: 56,
      height: 56,
      borderRadius: '50%',
      backgroundColor: palette.secondary.main,
      boxShadow: '0 2px 5px 0 hsla(0, 0%, 0%, .26)',
      color: 'hsl(0, 0%, 100%)',
      textAlign: 'center',
      lineHeight: '56px',
      cursor: 'pointer',
      userSelect: 'none',
      outline: 0,
      padding: 0,
      border: 0
    },
    navMenuButtonIcon: {
      fontSize: '1.3em',
      transition: 'transform .2s'
    },
    menuItemsWrapper: {
      padding: 0,
      margin: 0
    }
  };
});

interface Props {
  className?: string;
  children: ReactElement | ReactElement[];
}

const NavMenu = ({ className = '', children }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function closeMenu(event: MouseEvent) {
      if (
        event.target &&
        menuRef.current &&
        menuRef.current.contains(event.target as Node)
      ) {
        return;
      }
      setIsOpen(false);
    }

    window.addEventListener('click', closeMenu);

    return function cleanup() {
      window.removeEventListener('click', closeMenu);
    };
  });

  const items = React.Children.map(children, (child, index) => {
    return React.cloneElement(child, {
      onClick: () => {
        setIsOpen(false);
      },
      isVisible: isOpen
    });
  });

  return (
    <div
      className={`${classes.navMenuContainer} ${
        isOpen ? classes.navMenuContainerOpen : ''
      }`}
      ref={menuRef}
    >
      <button
        className={classes.navMenuButton}
        type="button"
        onClick={() => setIsOpen(!isOpen)}
      >
        <NavMenuHamburgerIcon
          className={classes.navMenuButtonIcon}
          isOpen={isOpen}
        />
      </button>

      <menu className={classes.menuItemsWrapper}>{items}</menu>
    </div>
  );
};

export default NavMenu;
