import React from 'react';
import MuiChip, { ChipProps } from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

const SmallCloseIcon = withStyles({
  root: {
    fontSize: '18px'
  }
})(CloseIcon);

const Tag = (props: ChipProps) => {
  return <MuiChip deleteIcon={<SmallCloseIcon />} {...props} />;
};

export default withStyles({
  root: {
    borderRadius: '20px',
    height: '24px'
  }
})(Tag);
