import React from 'react';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';

export default createSvgIcon(
  <React.Fragment>
    <circle cx="12" cy="12" r="12" fill="#FCB22B" />
    <path
      d="M16.13 8.04333C16.13 9.09261 15.7131 10.0989 14.9712 10.8409C14.2292 11.5828 13.2229 11.9997 12.1736 11.9997C11.1244 11.9997 10.1181 11.5828 9.3761 10.8409C8.63414 10.0989 8.21732 9.09261 8.21732 8.04333V5H16.13V8.04333Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M16.13 15.956C16.13 14.9067 15.7131 13.9004 14.9712 13.1584C14.2292 12.4165 13.2229 11.9996 12.1736 11.9996C11.1244 11.9996 10.1181 12.4165 9.3761 13.1584C8.63414 13.9004 8.21732 14.9067 8.21732 15.956V18.9993H16.13V15.956Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M7 5.00085H17.3473"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 19H17.3473"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.21732 16.5653H10.3476L12.1736 14.7393L13.9996 16.5653H16.13"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.06703 10.4785H15.2803"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </React.Fragment>,
  'HourglassIcon'
);
