import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItemText from 'components/ListItemText';

const useStyles = makeStyles(() => ({
  root: {
    display: 'inline-block',
    flex: '0 0 20px',
    lineHeight: '18px',
    padding: '0 2px',
    textAlign: 'right'
  }
}));

interface Item {
  text: string;
}

interface Props {
  number: number;
}

function ListItemNumber({ number }: Props) {
  const classes = useStyles();
  return (
    <ListItemText button className={classes.root}>
      {number}.
    </ListItemText>
  );
}

export default ListItemNumber;
