import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import SmallAddCircleIcon from 'icons/SmallAddCircleIcon';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DragUpdate
} from 'react-beautiful-dnd';
import { Field, FieldArray } from 'formik';
import { IngredientType, IngredientListType } from 'types/recipes';

import TextField from 'components/TextField';

interface Classes {
  root: string;
  row: string;
  name: string;
  quantity: string;
  type: string;
  dragHandle: string;
  addButton: string;
  deleteButton: string;
  sectionTitle: string;
}

type IngredientProps = {
  classes: Classes;
  autoFocus: boolean;
  ingredient: IngredientType;
  fieldPrefix: string;
  onChange(e: any): void;
  onBlur(e: any): void;
  onRemove(e: any): void;
  onEnterKey(e: any): void;
  index: number;
};

function Ingredient({
  classes,
  autoFocus,
  ingredient,
  fieldPrefix,
  onChange,
  onBlur,
  onRemove,
  onEnterKey,
  index
}: IngredientProps) {
  return (
    <Draggable draggableId={`${fieldPrefix}-draggable-${index}`} index={index}>
      {provided => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={classes.row}
        >
          <div {...provided.dragHandleProps} className={classes.dragHandle}>
            <DragHandleIcon color="action" />
          </div>
          <Field
            inputProps={{
              name: `${fieldPrefix}.ingredients.${index}.name`
            }}
            className={classes.name}
            component={TextField}
            label="Name"
            placeholder="Name"
            margin="dense"
            variant="outlined"
            onChange={onChange}
            onBlur={onBlur}
            onEnterKey={onEnterKey}
            value={ingredient.name}
            autoFocus={autoFocus}
          />
          <Field
            inputProps={{
              name: `${fieldPrefix}.ingredients.${index}.quantity`
            }}
            className={classes.quantity}
            component={TextField}
            label="Qty"
            placeholder="Qty"
            margin="dense"
            variant="outlined"
            helperText="# only"
            onChange={onChange}
            onBlur={onBlur}
            onEnterKey={onEnterKey}
            value={ingredient.quantity}
          />
          <Field
            inputProps={{
              name: `${fieldPrefix}.ingredients.${index}.type`
            }}
            className={classes.type}
            component={TextField}
            label="Type"
            placeholder="Type"
            margin="dense"
            variant="outlined"
            onChange={onChange}
            onBlur={onBlur}
            onEnterKey={onEnterKey}
            value={ingredient.type}
          />
          <IconButton className={classes.deleteButton} onClick={onRemove}>
            <RemoveCircleIcon color="action" />
          </IconButton>
        </div>
      )}
    </Draggable>
  );
}

type Props = {
  classes: Classes;
  ingredientList: IngredientListType;
  fieldPrefix: string;
  onChange(e: any): void;
  onBlur(e: any): void;
  onSectionRemove(e: any): void;
  showSectionTitle: boolean;
};

const IngredientListEdit = ({
  classes,
  fieldPrefix,
  ingredientList,
  onChange,
  onBlur,
  onSectionRemove,
  showSectionTitle
}: Props) => {
  const [autoFocusIndex, setAutoFocusIndex] = useState(-1);

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <Field
          className={`${classes.sectionTitle} ${
            showSectionTitle ? 'is-visible' : ''
          }`}
          inputProps={{ name: `${fieldPrefix}.title` }}
          component={TextField}
          label="Section Title"
          placeholder="Section Title"
          margin="dense"
          variant="outlined"
          value={ingredientList.title}
          onChange={onChange}
          onBlur={onBlur}
        />
        {showSectionTitle && (
          <IconButton
            className={classes.deleteButton}
            onClick={onSectionRemove}
          >
            <DeleteIcon color="action" />
          </IconButton>
        )}
      </div>
      <FieldArray
        name={`${fieldPrefix}.ingredients`}
        render={arrayHelpers => {
          function onDragEnd(result: DragUpdate) {
            // dropped outside the list
            if (!result.destination) {
              return;
            }

            if (result.destination.index === result.source.index) {
              return;
            }

            arrayHelpers.move(result.source.index, result.destination.index);
          }

          return (
            <div>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={`${fieldPrefix}-droppable`}>
                  {provided => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {ingredientList.ingredients &&
                        ingredientList.ingredients.length > 0 &&
                        ingredientList.ingredients.map((ingredient, index) => (
                          <Ingredient
                            key={index}
                            classes={classes}
                            ingredient={ingredient}
                            fieldPrefix={fieldPrefix}
                            onChange={onChange}
                            onBlur={onBlur}
                            onRemove={() => arrayHelpers.remove(index)}
                            onEnterKey={() => {
                              arrayHelpers.insert(index + 1, {
                                name: '',
                                quantity: '',
                                type: ''
                              });
                              setAutoFocusIndex(index + 1);
                            }}
                            autoFocus={autoFocusIndex === index}
                            index={index}
                          />
                        ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              <div>
                <Button
                  color="primary"
                  className={classes.addButton}
                  onClick={() => {
                    arrayHelpers.push({ name: '', quantity: '', type: '' });
                    setAutoFocusIndex(ingredientList.ingredients.length);
                  }}
                >
                  <SmallAddCircleIcon color="primary" />
                  Add Item
                </Button>
              </div>
            </div>
          );
        }}
      />
    </div>
  );
};

export default withStyles({
  root: {},
  row: {
    display: 'flex',
    alignItems: 'start',
    '&:first-child': {
      marginTop: 4
    }
  },
  name: {
    flex: 2,
    marginRight: '16px'
  },
  quantity: {
    flex: 1,
    marginRight: '16px'
  },
  type: {
    flex: 1.5,
    marginRight: '6px'
  },
  dragHandle: {
    marginTop: '8px',
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingRight: '8px'
  },
  addButton: {
    paddingLeft: 0
  },
  deleteButton: {
    marginTop: '8px'
  },
  sectionTitle: {
    display: 'none',
    flex: 1,
    '&.is-visible': {
      display: 'inline-flex'
    }
  }
})(IngredientListEdit);
