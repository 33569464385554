import React from 'react';
import { History } from 'history';
import { Link as RouterLink } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import EditIcon from '@material-ui/icons/Edit';
import { useSnackbar } from 'notistack';

import ChefIcon from 'icons/ChefIcon';

import { RecipeType } from 'types/recipes';
import { ADD_CREATION_EVENT } from 'queries';

type Response = {
  markAsMade: RecipeType;
};

type Variables = {
  id: string;
};

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12
  },
  sectionDesktop: {
    display: 'flex'
  }
}));

interface Props {
  // non-style props
  recipeId: string;
  title: string;
  history: History;
}

interface BackButtonProps {
  className: string;
  history: History;
}

function BackButton({ className, history }: BackButtonProps) {
  const { state } = history.location;

  if (state && state.useHistoryBack) {
    return (
      <IconButton className={className} onClick={() => history.goBack()}>
        <ArrowBackIcon />
      </IconButton>
    );
  }

  return (
    <IconButton className={className} component={RouterLink} to="/">
      <ArrowBackIcon />
    </IconButton>
  );
}

function ViewRecipeAppBar({ recipeId, title, history }: Props) {
  const [hasMarkedAsMade, setHasMarkedAsMade] = React.useState(false);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [addCreationEvent] = useMutation<Response, Variables>(
    ADD_CREATION_EVENT,
    {
      onCompleted: () => {
        enqueueSnackbar('I made dis.', {
          variant: 'success'
        });
      },
      onError: () => {
        enqueueSnackbar('Unable to mark as made.', { variant: 'error' });
      }
    }
  );

  return (
    <AppBar position="sticky" color="default" elevation={1}>
      <Toolbar>
        <BackButton className={classes.menuButton} history={history} />
        <Typography
          className={classes.grow}
          color="inherit"
          variant="h6"
          noWrap
        >
          {title}
        </Typography>
        <div className={classes.sectionDesktop}>
          <IconButton
            color="inherit"
            component={RouterLink}
            to={`/recipe/${recipeId}/edit`}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            color="inherit"
            disabled={hasMarkedAsMade}
            onClick={() => {
              addCreationEvent({
                variables: { id: recipeId }
              });
              setHasMarkedAsMade(true);
            }}
          >
            <ChefIcon />
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default ViewRecipeAppBar;
