import React from 'react';
import MuiTabs, { TabsProps } from '@material-ui/core/Tabs';
import { withStyles } from '@material-ui/core/styles';

const Tabs = (props: TabsProps) => {
  return <MuiTabs indicatorColor="primary" textColor="primary" {...props} />;
};

export default withStyles({
  root: {
    // borderRadius: "20px",
    // height: "24px",
  }
})(Tabs);
