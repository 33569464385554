import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import { withStyles } from '@material-ui/core/styles';

const ExpansionPanelDetails = withStyles({
  root: {
    padding: '8px 0',
    margin: 0,
    display: 'block'
  }
})(MuiExpansionPanelDetails);

export default ExpansionPanelDetails;
